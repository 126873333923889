import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '../../icon';
import './index.scss';

export const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  modalStyles,
  bodyStyles,
  headerStyles,
  footerStyles,
  settings = 'standart',
  footer = null,
}) => {
  return (
    <div
      className={cx('cmp-modal flex items-start py-10 justify-center', {
        _visible: isOpen,
      })}
    >
      <div className="backdrop" onClick={onClose} />

      <div className="modal" style={modalStyles}>
        {title && (
          <div
            className="modal-header flex items-center justify-between"
            style={headerStyles}
          >
            <div className="text-lg">{title}</div>
            <Icon
              name="close"
              size={14}
              onClick={onClose}
              className="cursor-pointer"
            />
          </div>
        )}

        <div className="modal-body" style={bodyStyles}>
          {children}
        </div>
        {settings === 'alert' && <div className="line" />}
        {footer && (
          <div className="modal-footer" style={footerStyles}>
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

Modal.displayName = 'Modal';
Modal.propTypes = {
  modalStyles: PropTypes.object,
  headerStyles: PropTypes.object,
  footerStyles: PropTypes.object,
  bodyStyles: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.any,
  title: PropTypes.any,
  settings: PropTypes.string,
  footer: PropTypes.any,
};
