import cx from 'classnames';
import PropTypes from 'prop-types';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import avatarLogo from '../../../../assets/images/avatar.svg';
import useClickOutside from '../../../../hooks/useClickOutside';
import { Button } from '../../ui/button';
import styles from './index.module.scss';
import {apiGet} from '../../../../api';
export const TreeNodeInfo = forwardRef((props, parentRef) => {
  const [totalCount, setTotalCount] = useState('');
  const { data } = props;
  const ref = useRef(null);

  useClickOutside(ref, () => {
    document.documentElement.classList.remove('overflow-hidden');
    ref.current.classList.remove(styles.wrapVisible);
  });

  useImperativeHandle(parentRef, () => ({
    open: () => {
      document.documentElement.classList.add('overflow-hidden');
      ref.current.classList.add(styles.wrapVisible);
    },
    close: () => {
      closeBlock();
    },
  }));

  const closeBlock = () => {
    document.documentElement.classList.remove('overflow-hidden');
    ref.current.classList.remove(styles.wrapVisible);
  };

  useEffect(() => {


    apiGet({
      url: '/smart-index/info'
    }).then(res => {
      setTotalCount(res?.data?.totalCount);
      console.log(totalCount);
      // setAveragePrice(res?.data?.averagePrice);
      // setCurrentRate(res?.data?.currentRate);
      // setIsFetch(true);
    })
      .catch(e => console.log(e));
  }, []);

  return (
    <div
      className={cx(
        styles.wrap,
        'scroll-y bg-bg fixed top-0 bottom-0 flex flex-col justify-between'
      )}
      ref={ref}
    >
      <div>


        <div className="bg-white px-12 py-8">
          <Row>
            <Col className="col-auto">
              <img
                src={data?.avatar || avatarLogo}
                className="md:w-20 md:h-20  h-16 w-16 rounded-md"
              />
            </Col>

            <Col className="pl-0">
              <div className="flex items-center justify-between mb-2">
                <div className="text-xs text-main-color">ID {data?.id}</div>
              </div>

              <div className="sm:text-xl text-text font-medium leading-tight mb-1">
                {data?.name}
              </div>

              <div className="text-xs text-textFade">
                С нами с {data?.registrationDate}
              </div>
            </Col>
          </Row>

          <ul className="mt-6">

            {data?.activeInvestSum === 0 ? <></> : (
              <li>
                <div className="text-sm -mb-1 font-light">
                    Активные инвестиции на сумму
                </div>
                <div className="sm:text-2xl text-lg font-medium">
                  {data?.activeInvestSum.toFixed(2)} USD
                </div>
              </li>
            )}
            
            {/*{data?.activeInvestSum && (*/}
            {/*  <li>*/}
            {/*    <div className="text-sm -mb-1 font-light">*/}
            {/*      Активные инвестиции на сумму*/}
            {/*    </div>*/}
            {/*    <div className="sm:text-2xl text-lg font-medium">*/}
            {/*      {data?.activeInvestSum.toFixed(2)} USD*/}
            {/*    </div>*/}
            {/*  </li>*/}
            {/*)}*/}

            {/*{data?.structureInvest === 0 ? <></> : (*/}
            <li className="mt-2">
              <div className="text-sm -mb-1 font-light">
                    Инвестиций в структуре
              </div>
              <div className="sm:text-2xl text-lg font-medium">
                {/*{totalCount.toFixed(2)} Smart index*/}
                {parseInt(data?.structureInvest).toFixed(2)} Smart index
              </div>
            </li>
            {/*)}*/}
            
            
            {/*{data?.structureInvest && (*/}
            {/*  <li className="mt-2">*/}
            {/*    <div className="text-sm -mb-1 font-light">*/}
            {/*      Инвестиций в структуре*/}
            {/*    </div>*/}
            {/*    <div className="sm:text-2xl text-lg font-medium">*/}
            {/*      {totalCount.toFixed(2)} Smart index*/}
            {/*      /!*{parseInt(data?.structureInvest).toFixed(2)} Smart index*!/*/}
            {/*    </div>*/}
            {/*  </li>*/}
            {/*)}*/}

            <li className="mt-2">
              <div className="text-sm -mb-1 font-light">Бизнес-пакет</div>
              <div className="sm:text-2xl text-lg font-medium">
                {data?.hasBusinessPacketActiveTo
                  ? data?.hasBusinessPacketActiveTo
                  : 'Отсутствует'}
              </div>
            </li>
            {data?.statusIcon && (
              <li className="mt-2">
                <div className="text-sm -mb-1 font-light">Квалификация</div>
                <img className="mt-1 md:w-20 md:h-20  h-16 w-16" src={data?.statusIcon} />
              </li>
            )}
            {data?.qualifications !== null && (
              <li className="mt-2">
                <div className="text-sm -mb-1 font-light">Квалификация</div>
                <div className="sm:text-2xl text-lg font-medium">{data?.qualifications}</div>
              </li>
            )}
            {/*{data?.level && (*/}
            {/*  <li className="mt-2">*/}
            {/*    <div className="text-sm -mb-1 font-light">Квалификация</div>*/}
            {/*    <div className="sm:text-2xl text-lg font-medium">{data?.level}/{data?.level/3}</div>*/}
            {/*  </li>*/}
            {/*)}*/}
          </ul>
        </div>

        <div className="sm:px-12 sm:py-8  px-8 py-4">
          <Row>
            <Col sm={6}>
              <Card>
                <CardBody className="p-4 pb-3">
                  <div className="text-xs">
                    Лично
                    <br />
                    приглашенных
                  </div>

                  <div className="text-xl text-main-color font-medium mt-1">
                    {data?.inviteCount ? data?.inviteCount : 0}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col sm={6}>
              <Card>
                <CardBody className="p-4 pb-3">
                  <div className="text-xs">
                    Партнеров
                    <br /> в структуре
                  </div>

                  <div className="text-xl text-main-color font-medium mt-1">
                    {data?.childrenCount ? data?.childrenCount : 0}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col sm={6}>
              <Card>
                <CardBody className="p-4 pb-3">
                  <div className="text-xs">Доход лично приглашенных</div>

                  <div className="text-xl text-main-color font-medium mt-1">
                    {data?.partnersProfit ? data?.partnersProfit : 0}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col sm={6}>
              <Card>
                <CardBody className="p-4 pb-3">
                  <div className="text-xs">
                    Доход
                    <br />
                    структуры
                  </div>

                  <div className="text-xl text-main-color font-medium mt-1">
                    {data?.structureProfit ? data?.structureProfit : 0}
                  </div>
                </CardBody>
              </Card>
            </Col>
            { data?.partnersWithActiveClubCount &&
              <Col sm={6}>
                <Card>
                  <CardBody className="p-4 pb-3">
                    <div className="text-xs">
                      Активных клубов RSI среди лично приглашенных
                    </div>

                    <div className="text-xl text-main-color font-medium mt-1">
                      {data?.partnersWithActiveClubCount ? data?.partnersWithActiveClubCount : 0}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            }
            { data?.partnersInStructureWithActiveClub &&
              <Col sm={6}>
                <Card>
                  <CardBody className="p-4 pb-3">
                    <div className="text-xs">
                      Активных клубов RSI в структуре
                    </div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className="text-xl text-main-color font-medium mt-1">
                      {data?.partnersInStructureWithActiveClub ? data?.partnersInStructureWithActiveClub : 0}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            }
            {/*{ data?.structureSmartLow !== 0 ? <></> :*/}
            <Col sm={6}>
              <Card>
                <CardBody className="p-4 pb-3">
                  <div className="text-xs">
                      Инвестиции в Smart до 10000 USDR
                  </div>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  <div className="text-xl text-main-color font-medium mt-1">
                    {data?.structureSmartLow}
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/*}*/}
            {/*{ data?.structureSmartHigh !== 0 &&*/}
            {/*{ data?.structureSmartHigh &&*/}
            <Col sm={6}>
              <Card>
                <CardBody className="p-4 pb-3">
                  <div className="text-xs">
                      Инвестиции в Smart от 10000 UDSR
                  </div>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  <div className="text-xl text-main-color font-medium mt-1">
                    {data?.structureSmartHigh}
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/*}*/}
          </Row>
        </div>
      </div>

      <div className="bg-white sm:px-12 sm:py-8  px-8 py-4">
        <Button size="md" color="primary" onClick={closeBlock}>
          Закрыть
        </Button>
      </div>
    </div>
  );
});
TreeNodeInfo.displayName = 'TreeNodeInfo';

TreeNodeInfo.propTypes = {
  data: PropTypes.any,
};
