import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { apiGet } from '../../../../api';
import Spinner from '../../../common/spinner';
import { TreeNodeInfo } from '../../../common/tree/nodeInfo';
import { Input } from '../../../common/ui/input';
import './index.scss';
import { LinearTreeGenerations } from './tree/linearTreeGenerations';
import { ParentLinearTreeGenerations } from './tree/parentLinearTree';
export const GenerationsPage = () => {
  const { register, watch, setValue } = useForm();

  const userDataRef = useRef(null);
  const [prifleInfo, setProfileInfo] = useState();
  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [membersData, setMembersData] = useState({});
  const [parents,setParents] = useState([]);
  const getMembersData = () => {
    apiGet({
      url: '/linear',
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка поиска поколений');
          return;
        }
        if (!res.error) {
          getChildrenData(res.data.id);
        }
      }
    });
  };

  const getChildrenData = id => {
    apiGet({
      url: `/linear/children?id=${id}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка поиска поколений');
          return;
        }
        if (!res.error) {
          setMembersData(res.data);
          setLoading(false);
        }
      }
    });
  };

  const getParentsData =()=> {
    apiGet({
      url: '/linear/parents',
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка поиска поколений');
          return;
        }
        if (!res.error) {
          setLoading(false);
          let revers= res.data.reverse();
          setParents(revers);
        }
      }
    });
  };
  

  useEffect(() => {
    getParentsData();
    getMembersData();

  }, []);

  const openUserData = userId => {
    apiGet({
      url: `/linear/detail?id=${userId}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка в загрузке profile');
          return;
        }
        if (!res.error) {
          setProfileInfo(res?.data);
          userDataRef.current.open(userId);
        }
      }
    });
  };

  const getSearchList = () => {
    apiGet({
      url: `/linear/find-children?q=${watch('searchName')}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка поиска поколений');
          return;
        }
        if (!res.error) {
          setSearchList(res.data);
        }
      }
    });
  };

  const searchUser = (name, id) => {
    apiGet({
      url: `/linear/children-with-parents?id=${id}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка поиска пользователя');
          return;
        }
        if (!res.error) {
          setMembersData(res.data);
          setSearchList([]);
        }
      }
    });
  };

  useEffect(() => {
    if (!watch('searchName')) {
      setSearchList([]);
      return;
    }

    getSearchList();
  }, [watch('searchName')]);

  const renderSearchList = (i, index) => {
    const goToPeople = () => {
      searchUser(i.name, i.id);
      setValue('searchName', i.name || i.id);
    };
    return (
      <div key={index} className="item" onClick={goToPeople}>
        {i.name}
        <br />
        {i.id && `id: ${i.id}`}
      </div>
    );
  };

  const renderItem = (i, index) => {
    return (
      <LinearTreeGenerations
        key={index}
        parentIndex={index}
        data={i}
        openUser={openUserData}
      />
    );
  };

  const renderParentItem = (i, index) => {
    if(index==0){
      return (
        <ParentLinearTreeGenerations
          key={index}
          parentIndex={index}
          data={i}
          parentchildren={[...parents]}
        />
      );
    }
  };

  return (
    <Container fluid>
      <Card className=" cmp-linear-generations my-10">
        <CardHeader className="p-4 ">
          <div className="row items-center">
            <div className="col">
              <div className="sm:text-2xl text-lg  font-medium">Поколения</div>
            </div>

            <div className="col-sm-auto relative">
              <Input
                placeholder="Поиск пользователя"
                fieldClass="w-full sm:w-60 mb-0"
                name="searchName"
                register={register}
                autoComplete="off"
              />
              <div className="search-list">
                {searchList.map(renderSearchList)}
              </div>
            </div>
          </div>
        </CardHeader>

        {loading ? (
          <Spinner style={{ margin: 'auto' }} size={11} />
        ) : (
          <CardBody className="p-4">
            {parents && parents.map((item,index)=>renderParentItem(item,index))}
            <div style={{paddingLeft:`${parents.length*7*0.25}rem`}} className='flex relative  flex-col child-inner-wrap   childrenStart'><div className='flex flex-col relative'>{membersData && renderItem(membersData)} </div></div>
          </CardBody>
        )}
      </Card>

      <TreeNodeInfo data={prifleInfo} ref={userDataRef} />
    </Container>
  );
};
