import cx from 'classnames';
import React, { useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, Container } from 'reactstrap';
import { useFetch } from '../../../../hooks/useFetch';
import { Icon } from '../../../common/icon';
import Spinner from '../../../common/spinner';
import { BinaryTree } from '../../../common/tree/binary';
import { BinaryStructureAddButtons } from './addButtons';
import './index.scss';
import { ScoresBlock } from './scoresBlock';

const Currentlysale = {
  series: [75],
  options: {
    chart: {
      type: 'radialBar',
    },
    colors: ['#711313'],
    plotOptions: {
      radialBar: {
        hollow: {
          size: '50%',
        },

        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 6,
            fontSize: '14px',
          },
        },
      },
    },
  },
};

const Currentlysale2 = {
  series: [23],
  options: {
    chart: {
      type: 'radialBar',
    },
    colors: ['#711313'],
    plotOptions: {
      radialBar: {
        hollow: {
          size: '50%',
        },

        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 6,
            fontSize: '14px',
          },
        },
      },
    },
  },
};

export const BinaryStructure = () => {
  const refAcc = useRef([]);

  const [viewAcc, setViewAcc] = useState(false);

  const { response: getBinaryData } = useFetch({ url: '/binary/info' });

  const toggleAcc = () => {
    setViewAcc(!viewAcc);
    console.log(refAcc);
    refAcc.current.map(elem=>elem.classList.toggle('_view'));
  };
  const renderStatuses = (data, index) => {
    if (index !== 0) {
      return (
        <div ref={el => refAcc.current[index] = el} className="acc-content col-xl-6 col-xxl-6 col-md-12 col-sm-12">
          <Card className="xs:p-2 mb-3">
            <div className="row reward">
              <div className="col">
                <div className="mb-0 flex">
                  <div className="chart-block">
                    <Chart
                      options={Currentlysale.options}
                      series={[data?.leftPercent.toFixed(0)]}
                      type="radialBar"
                      height={100}
                    />
                  </div>

                  <span className="flex flex-col justify-center items-start">
                    <span className=" status-name font-bold">
                      В левой ветке
                    </span>
                    <span className="font-medium text-purple ">
                      {data?.needLeft.toFixed(2)} USDR
                    </span>
                  </span>
                </div>
                <div className=" binary-prize mb-0 flex">
                  <div className="chart-block">
                    <Chart
                      options={Currentlysale2.options}
                      series={[data?.rightPercent.toFixed(0)]}
                      type="radialBar"
                      height={100}
                    />
                  </div>

                  <span className="flex flex-col justify-center items-start">
                    <span className=" status-name font-bold">
                      В правой ветке
                    </span>
                    <span className="font-medium text-purple ">
                      {data?.needRight} USDR
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 flex justify-center items-center">
                <div className="binary-prize mb-0 flex " style ={{ flexDirection:'column-reverse', width:150}} >
                  <div className="flex">
                    <div className="flex flex-col justify-center">
                      <div className="mt-1 flex">
                        Цель: <div className="price ml-2"> {data?.money}</div>
                      </div>
                      <div className="mt-1 flex">
                        Статус: <div className="price ml-2"> {data?.name}</div>
                      </div>
                      <div className="mt-1 flex items-end">
                        <span style={{maxWidth:100}}>Кол-во приглашенных:</span><div className="price ml-2"> {data?.childrenCount}</div>
                      </div>
                      <div className="mt-1 flex">
                        Награда: <div className="price ml-2"> {data?.gift}</div>
                      </div>
                    </div>
                  </div>
                  <img className="image-status ml-2" src={data?.icon} />
                </div>
              </div>
            </div>
          </Card>
        </div>
      );
    }
  };

  return (
    <Container fluid className="binary-prize">
      <div className="row mt-8">
        <div className="col-xl-6 col-xxl-6 col-md-12 col-sm-12">
          {getBinaryData?.data?.currentStatus && (
            <>
              <div className="sm:text-2xl text-xl font-medium mb-3">
                Текущий уровень
              </div>
              <Card className="xs:p-2 mb-3">
                <div className="row reward">
                  <div className="col">
                    <div className="mb-0 flex">
                      <div className="chart-block">
                        <Chart
                          options={Currentlysale.options}
                          series={[100]}
                          type="radialBar"
                          height={100}
                        />
                      </div>

                      <span className="flex flex-col justify-center items-start">
                        <span className="status-name font-bold">
                          В левой ветке
                        </span>
                        <span className="font-medium text-purple">
                          0 USDR
                        </span>
                      </span>
                    </div>
                    <div className=" binary-prize mb-0 flex">
                      <div className="chart-block">
                        <Chart
                          options={Currentlysale2.options}
                          series={[100]}
                          type="radialBar"
                          height={100}
                        />
                      </div>

                      <span className="flex flex-col justify-center items-start">
                        <span className="status-name font-bold">
                          В правой ветке
                        </span>
                        <span className="font-medium text-purple">
                          0 USDR
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 flex justify-center items-center">
                    <div className="binary-prize mb-0 flex" style ={{ flexDirection:'column-reverse', width:150,}} >
                      <div className="flex">
                        <div className="flex flex-col justify-center">
                          <div className="mt-1 flex">
                            Цель:{' '}
                            <div className="price ml-2">
                              {getBinaryData?.data?.currentStatus?.money}
                            </div>
                          </div>
                          <div className="mt-1 flex">
                            Статус:{' '}
                            <div className="price ml-2">
                              {getBinaryData?.data?.currentStatus?.name}
                            </div>
                          </div>
                          <div className="mt-1 flex items-end">
                            <span style={{maxWidth:100}}>Кол-во приглашенных:</span><div className="price ml-2"> {getBinaryData?.data?.currentStatus?.childrenCount}</div>
                          </div>
                          <div className="mt-1 flex">
                            Награда:{' '}
                            <div className="price ml-2">
                              {getBinaryData?.data?.currentStatus?.gift}
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        className="image-status ml-2"
                        src={getBinaryData?.data?.currentStatus?.icon}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </>
          )}
        </div>
      </div>
      <div className="sm:text-2xl text-xl font-medium">Следующий уровень</div>
      <div className="row mt-8">
        {getBinaryData ? (
          <div className="col-xl-6 col-xxl-6 col-md-12 col-sm-12">
            <Card className="xs:p-2 mb-3">
              <div className="row reward">
                <div className="col">
                  <div className="mb-0 flex">
                    <div className="chart-block">
                      {getBinaryData?.data?.nextStatusList?.[0] && (
                        <Chart
                          options={Currentlysale.options}
                          series={[
                            getBinaryData?.data?.nextStatusList?.[0]?.leftPercent.toFixed(0),
                          ]}
                          type="radialBar"
                          height={100}
                        />
                      )}
                    </div>

                    <span className="flex flex-col justify-center items-start">
                      <span className=" status-name font-bold">
                        В левой ветке
                      </span>
                      <span className="font-medium text-purple ">
                        {getBinaryData?.data?.nextStatusList?.[0]?.needLeft.toFixed(2)}{' '}
                        USDR
                      </span>
                    </span>
                  </div>
                  <div className=" binary-prize mb-0 flex">
                    <div className="chart-block">
                      {getBinaryData?.data?.nextStatusList?.[0] && (
                        <Chart
                          options={Currentlysale2.options}
                          series={[
                            getBinaryData?.data?.nextStatusList?.[0]?.rightPercent.toFixed(0),
                          ]}
                          type="radialBar"
                          height={100}
                        />
                      )}
                    </div>

                    <span className="flex flex-col justify-center items-start">
                      <span className=" status-name font-bold">
                        В правой ветке
                      </span>
                      <span className="font-medium text-purple ">
                        {getBinaryData?.data?.nextStatusList?.[0]?.needRight.toFixed(2)}{' '}
                        USDR
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 flex justify-center items-center">
                  <div className="binary-prize mb-0 flex"  style ={{ flexDirection:'column-reverse', width:150,}} >
                    <div className="flex">
                      <div className="flex flex-col justify-center">
                        <div className="mt-1 flex">
                          Цель:{' '}
                          <div className="price ml-2">
                            {getBinaryData?.data?.nextStatusList?.[0]?.money}
                          </div>
                        </div>
                        <div className="mt-1 flex">
                          Статус:{' '}
                          <div className="price ml-2">
                            {getBinaryData?.data?.nextStatusList?.[0]?.name}
                          </div>
                        </div>
                        <div  className="mt-1 flex items-end">
                          <span style={{maxWidth:100}}>Кол-во приглашенных:</span><div className="price ml-2"> {getBinaryData?.data?.nextStatusList?.[0]?.childrenCount}</div>
                        </div>
                        <div className="mt-1 flex">
                          Награда:{' '}
                          <div className="price ml-2">
                            {getBinaryData?.data?.nextStatusList?.[0]?.gift}
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="image-status ml-2"
                      src={getBinaryData?.data?.nextStatusList?.[0]?.icon}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        ) : (
          <Spinner styles={{ margin: 'auto' }} />
        )}
        {getBinaryData?.data?.nextStatusList.map(renderStatuses)}
      </div>
      <div
        className="row mb-6 mt-3 flex justify-center "
      >
        <button onClick={toggleAcc}>
          <div className={cx({ rotated: viewAcc, 'btn-acc': !viewAcc })}>
            <Icon name="arrowDownDouble" />
          </div>
        </button>
      </div>

      <div className="row">
        <div className="col-6">
          <ScoresBlock
            text="левой"
            people={getBinaryData?.data?.leftBranchUserCount}
            usdr={getBinaryData?.data?.leftBranchScore}
          />
        </div>
        <div className="col-6">
          <ScoresBlock
            text="правой"
            people={getBinaryData?.data?.rightBranchUserCount}
            usdr={getBinaryData?.data?.rightBranchScore}
          />
        </div>
      </div>

      <div className="mb-8">
        <BinaryStructureAddButtons btnConfirm={getBinaryData?.data?.setTo} />
      </div>
      <div className="relative">
        <BinaryTree />
      </div>
    </Container>
  );
};
