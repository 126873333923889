import { apiGet } from '../../../api';
import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Spinner from '../../common/spinner';
export const TableUsersVal = ({obj, tkey, req}) => {
  const [targetPop, setTargetPop] = useState([]);
  const [loader, setLoader] = useState(false);
  const[visible,setVisible] = useState(false);
  const getUsersId = tkey => {
    if(tkey=='6'){
      return false;
    }
    if(targetPop[0]== undefined){
      setLoader(true);
      apiGet({
        url: `/linear/partners-on-level?levelPercent=${tkey}`,
      }).then(res => {
        if (res) {
          if (res.error) {
            console.error('Ошибка в загрузке');
            setLoader(false);
            return;
          }
          if (!res.error) {
            if (res.data[0] != undefined) {
              setTargetPop(res.data);
              setLoader(false);
            } else {
              setTargetPop([{ id: 'Пусто' }]);
              setLoader(false);
            }
          }
        }
      });
    }
    setVisible(!visible);
  };

  return (
    <td
      onClick={() => {
        targetPop[0] == undefined ? getUsersId(req) : setVisible(!visible);
      }}
      id={`users${tkey}`}
      style={{
        position: 'relative',
        cursor: req != '6' ? 'pointer' :'unset',
      }}
      key={Math.random()}
      className={cx({
        _active: obj.isDone,
        'font-normal _wait': !obj.isDone,
      })}
    >
      <p>{obj.value}</p>
      {targetPop[0] != undefined && (
        <div onClick={()=>{setVisible(false);}} className="tooltip" placement="top" style={{display:`${visible ? 'block':'none'}`}}>
          {
            <ul style={{listStyle:'none', paddingLeft:2}}>
              {targetPop.map(item => {
                return <li key={Math.random()}>{item.id == 'Пусто' ? 'Пусто': 'ID: ' + item.id}</li>;
              })}
            </ul>
          }
        </div>
      )}
      {loader && <div className='waitTooltip'><Spinner></Spinner></div>}
    </td>
  );
};

TableUsersVal.propTypes={
  obj : PropTypes.object,
  tkey : PropTypes.number,
  req : PropTypes.any,
};