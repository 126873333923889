import { LoginPage } from '../components/pages/auth/login';
import { PassRecoverPage } from '../components/pages/auth/passRecover';
import { RegistrationPage } from '../components/pages/auth/registration';
import { FastLaunchPage1 } from '../components/pages/fastLaunch/1';
import { FastLaunchPage2 } from '../components/pages/fastLaunch/2';
import { FastLaunchPage3 } from '../components/pages/fastLaunch/3';
import { FastLaunchPage4 } from '../components/pages/fastLaunch/4';
import { page500 } from '../components/pages/main/500';
import { AppealPage } from '../components/pages/main/appeal';
import { AppealReadPage } from '../components/pages/main/appealRead';
import { BinaryStructure } from '../components/pages/main/binaryStructure';
import { Page404 } from '../components/pages/main/error/404';
import { FastLaunchWelldone } from '../components/pages/main/fastLaunchWelldone';
import { GenerationsPage } from '../components/pages/main/generations';
import { HomePage } from '../components/pages/main/home';
import { LessonPage } from '../components/pages/main/lesson';
import { LinearStructure } from '../components/pages/main/linearStructure';
import { MaterialsPage } from '../components/pages/main/materials';
import { NotificationsPage } from '../components/pages/main/notifications';
import { Profile } from '../components/pages/main/profile';
import { ProfileVerification } from '../components/pages/main/profile/verification';
import { SmartInStructure } from '../components/pages/main/smartInStructure';
import { TradingWithRsi } from '../components/pages/main/tradingWithRsi';
import { TrainingPage } from '../components/pages/main/training';
import { CoursesLevel2 } from '../components/pages/main/CoursesLevel2';
import { CoursesLevel2In } from '../components/pages/main/CoursesLevel2In';
import { PasswordResetPage } from '../components/pages/auth/PasswordReset';
import { ProfitPage } from '../components/pages/main/profit';
import { Structures } from '../components/pages/structures';
import WalletPageUpdate from '../components/pages/main/newWallet';
export const mainRoutes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/profile-verification',
    component: ProfileVerification,
  },
  {
    path: '/trading-with-rsi',
    component: TradingWithRsi,
  },
  {
    path: '/appeal/:id',
    component: AppealReadPage,
  },
  {
    path: '/lesson/:id',
    component: LessonPage,
  },
  // {
  //   path: '/wallet',
  //   component: WalletPage,
  // },
  {
    path: '/wallet',
    component: WalletPageUpdate,
  },
  {
    path: '/materials',
    component: MaterialsPage,
  },
  {
    path: '/smart-in-structure',
    component: SmartInStructure,
  },
  {
    path: '/appeal',
    component: AppealPage,
  },
  {
    path: '/generations',
    component: GenerationsPage,
  },
  {
    path: '/linear-structure',
    component: LinearStructure,
  },
  {
    path: '/binary-structure',
    component: BinaryStructure,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/training',
    component: TrainingPage,
  },
  {
    path: '/notifications',
    component: NotificationsPage,
  },
  {
    path: '/fast-launch-welldone',
    component: FastLaunchWelldone,
  },
  {
    path: '/page500',
    component: page500,
  },
  {
    path: '/courses-level2',
    component: CoursesLevel2,
  },
  {
    path: '/courses-level2/:type',
    component: CoursesLevel2In,
  },
  {
    path:'/profit',
    component:ProfitPage,
  },
  {
    path:'/structures',
    component: Structures,
  },
  {
    path: '*',
    component: Page404,
  },
];

export const authRoutes = [
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/reset-pass',
    component: PasswordResetPage,
  },
  {
    path: '/registration',
    component: RegistrationPage,
  },
  {
    path: '/password-recover',
    component: PassRecoverPage,
  },
  {
    path: '/fast-launch/step-1',
    component: FastLaunchPage1,
  },
  {
    path: '/fast-launch/step-2',
    component: FastLaunchPage2,
  },
  {
    path: '/fast-launch/step-3',
    component: FastLaunchPage3,
  },
  {
    path: '/fast-launch/step-4',
    component: FastLaunchPage4,
  },

];
