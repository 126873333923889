import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useFetch } from '../../../../hooks/useFetch';
import Spinner from '../../../common/spinner';
import { StaticBlock } from '../../../common/staticBlock';
import './styles.scss';
import PropTypes from 'prop-types';

export const ProfitPage = () => {

  const { response: profitData, loading: loadingProfitData } = useFetch({
    url: '/profit',
  });

 
  return (
    <Container fluid>
      <div className="sm:text-2xl text-lg my-8 font-medium">
        Финансовые показатели
      </div>

      <Row className="mt-3">
        {loadingProfitData ? (
          <Spinner styles={{ margin: 'auto' }} />
        ) : (
          <>
            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#711313"
                value={profitData?.data?.previousMonthProfitBinary}
                iconName="statHouse"
                title="Доход  за предыдущий месяц бинарный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#711313"
                value={profitData?.data?.previousMonthProfitLinear}
                iconName="bank"
                title="Доход за предыдущий месяц линейный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#711313"
                value={profitData?.data?.monthProfitBinary}
                iconName="list"
                title="Доход за текущий месяц бинарный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#711313"
                value={profitData?.data?.monthProfitLinear}
                iconName="lineChart"
                title="Доход за текущий месяц линейный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#711313"
                value={profitData?.data?.yearProfitBinary}
                iconName="invoice"
                title="Годовой доход бинарный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#711313"
                value={profitData?.data?.yearProfitLinear}
                iconName="building"
                title="Годовой доход линейный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#711313"
                value={profitData?.data?.totalProfitBinary}
                iconName="agenda"
                title="Общий доход бинарный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#711313"
                value={profitData?.data?.totalProfitLinear}
                iconName="cabinet"
                title="Общий доход линейный"
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

ProfitPage.propTypes = {
  existBinary: PropTypes.bool,
  existLinear: PropTypes.bool,
};


export default ProfitPage;