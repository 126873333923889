import PropTypes from 'prop-types';
import React from 'react';
import spinnerBlueImg from './spinner-blue.svg';
import spinnerWhiteImg from './spinner-white.svg';

const Spinner = ({ size = 120, color = '#711313', styles, ...props }) => (
  <img
    alt="spinner animation"
    src={color === '#711313' ? spinnerBlueImg : spinnerWhiteImg}
    style={{ width: size, height: size, ...styles }}
    {...props}
  />
);
Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  styles: PropTypes.object,
};

export default Spinner;
