import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { apiGet } from '../../../../../api';
import { TreeNode } from '../../node';

export const BinaryTreeNode = ({
  parentIndex,
  openUser,
  data: { id, name, percent, totalInvest,totalSamrtLow,totalSmartHigh, avatar, children, isHasChildren },
}) => {
  const ref = useRef(null);

  const [childrenData, setChildrenData] = useState([]);

  const toggleChild = () => {
    apiGet({
      url: `/binary/children?id=${id}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка в загрузке children');
          return;
        }
        if (!res.error) {
          setChildrenData([...res.data.children]);
          ref.current.classList.toggle('w-0');
          ref.current.classList.toggle('h-0');
          ref.current.classList.toggle('overflow-hidden');
        }
      }
    });
  };

  useEffect(() => {
    setChildrenData(children);
  }, [children]);

  const renderChild = (i, index) => {
    return (
      <div
        key={index}
        className={`child-wrap relative flex flex-col items-center px-1 child-wrap-${index}`}
      >
        <BinaryTreeNode
          openUser={openUser}
          data={i}
          parentIndex={parentIndex + 1}
        />
      </div>
    );
  };

  return (
    <>
      <div
        className={cx('relative node-wrap', { '_has-child': isHasChildren })}
      >
        <TreeNode
          id={id}
          percent={percent}
          name={name}
          avatar={avatar}
          totalInvest={totalInvest}
          totalSamrtLow={totalSamrtLow}
          totalSmartHigh={totalSmartHigh}
          hasChild={Boolean(isHasChildren)}
          toggleChild={toggleChild}
          onClick={openUser}
          val={'binary'}
        />
      </div>

      {/* Была логика на isParentIndex */}

      {childrenData && (
        <div
          ref={ref}
          className={cx('flex mt-6 px-2 relative child-main-wrap', {
            'w-0 h-0 overflow-hidden': !children,
          })}
        >
          {childrenData.map(renderChild)}
        </div>
      )}
    </>
  );
};
BinaryTreeNode.propTypes = {
  data: PropTypes.object,
  openUser: PropTypes.func,
  parentIndex: PropTypes.number,
  totalInvest: PropTypes.any,
  avatar: PropTypes.any,
};
