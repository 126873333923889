import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { Icon } from '../../../common/icon';
import { Pagination } from '../../../common/pagination';
import Spinner from '../../../common/spinner';
import { Button } from '../../../common/ui/button';
import './index.scss';

export const MaterialsPage = () => {
  const { page } = useUrl();

  const { response: materialsData, loading: loadingMaterials } = useFetch({
    url: `/material?page=${page || 1}&limit=10`,
    defaultValue: {},
  });

  const renderMaterials = (item, index) => {
    return (
      <Col key={index} xs={12} className="md:mb-7 mb-3">
        <Card className="m-0">
          <CardBody className="p-4">
            <div className="row items-center">
              <div className="col">
                <div className="content">
                  <div className="name">{item?.name}</div>

                  <div className="des-file">
                    <span className="date">
                      {item?.date} - {item?.size}
                    </span>
                  </div>
                </div>
              </div>

              {item.link && (
                <div className="col-auto">
                  <a
                    target="_blank"
                    href={item.link}
                    className="text-main-color"
                    rel="noreferrer"
                  >
                    <Button color="primary" className="h-full p-3">
                      <Icon color="#fff" name="copy" size={20} />
                    </Button>
                  </a>
                </div>
              )}

              {item.fileUrl && (
                <div className="col-auto pl-0">
                  <a target="_blank" href={item.fileUrl} rel="noreferrer">
                    <Button color="primary" className="h-full p-3">
                      <Icon color="#fff" name="download" size={20} />
                    </Button>
                  </a>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <Container fluid>
      <div className="sm:text-2xl text-lg my-9 font-medium">Материалы</div>

      <Row className="lg:text-lg text-md text-text">
        {loadingMaterials ? (
          <Spinner styles={{ margin: 'auto' }} />
        ) : (
          materialsData.data?.models?.map(renderMaterials)
        )}
      </Row>

      <div className="text-center">
        <Pagination lastPage={materialsData.data?.lastPage || 1} page={page} />
      </div>
    </Container>
  );
};
