import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { StaticBlock } from '../../common/staticBlock';
import PropTypes from 'prop-types';

export const Structures = () => {
  const { existBinary, existLinear } = useSelector(s => s.user.profile);

  return (
    <Container fluid>
      <div className="sm:text-2xl text-lg my-8 font-medium">Моя структура</div>
      <Card className="mb-8 p-4">
        <Row className="items-center justify-center">
          {existLinear ? (
            <Link
              to="/linear-structure"
              className="lg:mb-0 mb-4 cursor-pointer col-sm-6 col-lg-6"
            >
              <StaticBlock
                bg="rgb(113, 19, 19)"
                value="Линейная структура"
                iconName="linearStructure"
              />
            </Link>
          ) : (
            ''
          )}
          {existBinary ? (
            <Link
              to="/binary-structure"
              className="lg:mb-0 mb-4 cursor-pointer col-sm-6 col-lg-6"
            >
              <StaticBlock
                bg="rgb(113, 19, 19)"
                value="Бинарная структура"
                iconName="binaryStructure"
              />
            </Link>
          ) : (
            ''
          )}
        </Row>
      </Card>
    </Container>
  );
};

Structures.propTypes = {
  existBinary: PropTypes.bool,
  existLinear: PropTypes.bool,
};

export default Structures;
