import copy from 'copy-to-clipboard';
import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container } from 'reactstrap';
import { useFetch } from '../../../../hooks/useFetch';
import { Icon } from '../../../common/icon';
import Spinner from '../../../common/spinner';
import { Button } from '../../../common/ui/button';
import { Modal } from '../../../common/ui/modal';
import './index.scss';

export const TradingWithRsi = () => {
  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');

  const { response: dataTradingWithRsi, loading: loadingData } = useFetch({
    url: '/club/view',
  });

  const handleAlertOpen = text => {
    setAlertView(true);
    setAlertText(text);
  };
  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  const copyLink = () => {
    copy(dataTradingWithRsi?.data?.inviteToChannelLink);
    handleAlertOpen('Ссылка на приватный канал успешно скопирована');
  };
  return (
    <>
      <Container fluid>
        <div className="md:flex justify-between">
          <div className="sm:text-2xl my-5 md:my-10 text-lg font-medium flex ">
            Торговля с RSI
          </div>
          <div className="flex items-center mb-2 md:mb-0"></div>
        </div>

        <Card>
          <CardBody className="overflow-auto">
            {loadingData ? (
              <Spinner styles={{ margin: 'auto' }} />
            ) : (
              <div className="row">
                {dataTradingWithRsi?.data?.inviteToChannelLink === null ? (
                  <div className="flex-col flex justify-center lg:flex lg:flex-row lg:justify-start items-center">
                    <Icon name="warning" size={34} color="#F73434" />
                    <div className="ml-4" style={{ maxWidth: '700px' }}>
                      Необходимо{' '}
                      <Link
                        className="text-main-color font-bold"
                        to={'/business-package'}
                      >
                        купить
                      </Link>{' '}
                      подписку на год, для отображения контента
                    </div>
                  </div>
                ) : (
                  <>
                    <Col md={6} className="text-xl">
                      Поздравляем!
                      <br />
                      Ваш уникальный доступ на закрытый канал сообщества "Клуб
                      RSI" <b>АКТИВИРОВАН*</b>.
                      <br />
                      <br />
                      <div className="font-italic">
                        *Доступ предоставлен только вашему ID и является
                        одноразовым, в случае передачи ссылки третьим лицам,
                        ключ будет деактивирован без права восстановления.{' '}
                      </div>
                      <div className="mt-3">
                        <Button color="primary" onClick={copyLink}>
                          Скопировать ссылку
                        </Button>
                      </div>
                    </Col>
                    <Col
                      md={6}
                      className="flex flex-col items-center justify-center mt-2 md:mt-0"
                    >
                      <QRCode
                        style={{ minWidth: 200, minHeight: 200 }}
                        className="mb-3"
                        value={dataTradingWithRsi?.data?.inviteToChannelLink}
                      />
                    </Col>
                  </>
                )}
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </>
  );
};
