import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ParentTreeGeneration } from './parentTreeGeneration';

export const ParentLinearTreeGenerations = ({
  parentIndex,
  openUser,
  parentchildren,
  data: { id, percent, name, totalInvest,totalSamrtLow,totalSmartHigh, avatar, children,sk,tg,vk,fb },
}) => {
  const ref = useRef(null);
  const [childrenData, setChildrenData] = useState([]);



  const renderChild = (i, ind) => {
    if (parentchildren.length != 0 && ind == 0) {
      return (
        <div
          key={ind}
          className={cx('flex flex-col relative pr-6 mt-6', {
            _last: !parentIndex && ind === 2,
          })}
        >
          <ParentLinearTreeGenerations
            openUser={openUser}
            parentIndex={parentIndex + 1}
            data={i}
            parentchildren={parentchildren}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    if (parentchildren && !children) {
      parentchildren.shift();
      setChildrenData([...parentchildren]);
      ref.current.classList.toggle('w-0');
      ref.current.classList.toggle('h-0');
    }
  }, []);

  return (
    <>
      <div
        className={cx('relative node-wrap')}
      >
        <ParentTreeGeneration
          id={id}
          percent={percent}
          name={name}
          totalInvest={totalInvest}
          totalSamrtLow={totalSamrtLow}
          totalSmartHigh={totalSmartHigh}
          avatar={avatar}
          totalIndex={parentIndex}
          hasChild={Boolean(childrenData)}
          onClick={openUser}
          sk={sk}
          vk={vk}
          tg={tg}
          fb={fb}
        />
      </div>

      {childrenData && (
        <div
          ref={ref}
          className={cx(
            'flex relative child-wrap flex-col child-inner-wrap pl-7 overflow-hidden ',
            {
              'flex-col child-inner-wrap pl-7  overflow-hidden': parentIndex,
              'w-0 h-0': !children?.length,
            }
          )}
        >
          {childrenData.map((item, index) => renderChild(item, index))}
        </div>
      )}
    </>
  );
};
ParentLinearTreeGenerations.propTypes = {
  parentIndex: PropTypes.number,
  openUser: PropTypes.func,
  data: PropTypes.object,
  parentchildren: PropTypes.array,
};
