/* eslint-disable */
import React, {Fragment, useEffect, useState} from 'react';
import {apiGet, apiPost} from '../../../../api';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {Icon} from '../../../common/icon';
import ReactTooltip from 'react-tooltip';
import {Modal} from '../../../common/ui/modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Button} from '../../../common/ui/button';
import QRCode from 'qrcode.react';
import {Input} from '../../../common/ui/input';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {BillBlock} from '../../../common/billBlock';
import {getActiveClass} from '../../../../helpers/className';
import useDebounce from '../../../../hooks/useDebounce';
import {NotifyBlock} from '../../../common/notify';
import Spinner from '../../../common/spinner';
import logo from '../../../../assets/images/logo.png'
import {EachWalletPage} from './eachWalletPage';

const WalletPageUpdate = () => {
    const {register, setValue, watch, reset} = useForm();
    const [walletsData, setWalletsData] = useState(null);
    const [eachWalletName, setEachWalletName] = useState({});
    const [loaderSend, setLoaderSend] = useState(false);

    // Open and close states for modals
    const [balanceSetModal, setBalanceSetModal] = useState(false);
    const [balanceOutModal, setBalanceOutModal] = useState(false);
    const [alertView, setAlertView] = useState(false);
    const [usdrBalanceOutModal, setUsdrBalanceOutModal] = useState(false);
    const [balanceOutUsdrModal, setBalanceOutUsdrModal] = useState(false);
    const [withdrawBalanceUsdrModal, setWithdrawBalanceUsdrModal] = useState(false);

    // Показывать Btc в блоке renderBillBlock
    const [isBtcShow, setIsBtcShow] = useState(true);

    // Текст для окна уведомления
    const [alertText, setAlertText] = useState('');
    const [recipientAddress, setRecipientAddress] = useState('');

    // Получить коммиссию по всем кошелькам
    const [commissionSend, setCommissionSend] = useState(null);

    // Скрытые инпуты показать
    const [showInputs, setShowInputs] = useState(false);
    // const [isActiveButton, setIsActiveButton] = useState({});

    const [investWallet, setInvestWallet] = useState(null);
    const [investWalletUsdr, setInvestWalletUsdr] = useState(null);
    const [fiatWalletsData, setFiatWalletsData] = useState(null);

    const [availableWalletsToReplenish, setAvailableWalletsToReplenish] = useState(null);
    const [availableWalletsToPayout, setAvailableWalletsToPayout] = useState(null);


    const [userInfoById, setUserInfoById] = useState(null);
    const [error, setError] = useState(null);
    const [errorFromHandler, setErrorFromHandler] = useState(null);
    const [isButtonEnable, setIsButtonEnable] = useState(false);


    const [isShowEachWalletPage, setIsShowEachWalletPage] = useState(false);
    const [nameForEachWalletPage, setNameForEachWalletPage] = useState('');

    // Для обновления истории кошелька
    const [isRefreshWallet, setIsRefreshWallet] = useState(false);


    const [validateSendWallet, setValidateSendWallet] = useState({
        emailCode: false,
        smsCode: false,
        gaCode: false,
    });

    const history = useHistory();

    const closeModal = () => {
        setIsButtonEnable(false)
        setIsBtcShow(true)
        setShowInputs(false)
        setBalanceSetModal(false);
        setBalanceOutModal(false);
        setAlertView(false);
        setUsdrBalanceOutModal(false);
        setBalanceOutUsdrModal(false);
        setWithdrawBalanceUsdrModal(false);
        setError({});
        setErrorFromHandler(null)

        setValidateSendWallet({
            emailCode: false,
            smsCode: false,
            gaCode: false,
        })

        reset({
            incomePay: '',
            cryproToUsdrPay: '',
            usdrCounter: '',
            cryptoCounter: '',
            IdForUser: '',
            sumForUser: '',
            recipientAddress: '',
            recipientSum: '',
            recipientCommissionSum: '',
            code: '',
            codeAuth: '',
            email_Code: '',
            sms_Code: '',
            ga_Code: '',
        });
    };

    // Обновление кошельков
    const refreshWallet = () => {
        // apiGet({
        //     url: '/address'
        // }).then(res => {
        //     if (res.error)
        //         return;
        //
        //     setWalletsData(res.data);
        // });

        apiGet({
            url: `/wallet/crypto`
        }).then(res => {
            if (res.error)
                return
            setWalletsData(res.data)
            const syncWalletsData = res.data

            apiGet({
                url: `/wallet/usdr`
            }).then(res => {
                if (res.error)
                    return
                setInvestWalletUsdr(res.data)

                let arrToReplenish = []
                let arrToPayout = []
                res.data.availableWalletsToReplenish.map(itemRepl => {
                    syncWalletsData.map(item => {
                        if (item.name === itemRepl)
                            arrToReplenish.push(item)
                    })
                })
                setAvailableWalletsToReplenish(arrToReplenish)

                res.data.availableWalletsToPayout.map(itemRepl => {
                    syncWalletsData.map(item => {
                        if (item.name === itemRepl)
                            arrToPayout.push(item)
                    })
                })
                setAvailableWalletsToPayout(arrToPayout)
            })
        })

        // apiGet({
        //     url: `/wallet/fiat`
        // }).then(res => {
        //     if (res.error)
        //         return
        //     setFiatWalletsData(res.data)
        // })
    };

    useEffect(() => {
        // Обновление кошельков
        refreshWallet();
    }, []);

    const openModalBalanceIncome = (walletName, walletAddress) => {
        const data = {
            name: walletName,
            address: walletAddress
        };
        setEachWalletName(data);
        if (walletName === 'USDR')
            return setUsdrBalanceOutModal(true);
        setBalanceSetModal(true);
    };

    const openModalBalanceOut = (walletName, walletBalance) => {
        const data = {
            name: walletName,
            balance: walletBalance,
        };
        setEachWalletName(data);
        if (walletName === 'USDR')
            return setBalanceOutUsdrModal(true);
        setBalanceOutModal(true);
    };

    // Открытие модалки уведомлений
    const handleAlertOpen = (text) => {
        setAlertView(true);
        setAlertText(text);
    };

    const getToNewWallet = (walletName, walletBalance) => {
        setIsBtcShow(false)
        const data = {
            name: walletName,
            balance: walletBalance
        };
        setEachWalletName(data);
        setWithdrawBalanceUsdrModal(true);
    };

    const getCommission = (value = 'ETH') => {

        apiGet({
            url: `/commissions?to=${value}`,
        }).then(res => {
            if (res.error)
                return console.log(res.data);
            setCommissionSend(res.data);
            setShowInputs(true);
        }).catch(e => {
            console.log(e);
        });
    };

    const recipientAddressOnChange = ({target: {value}}) => {
        getCommission(value)
        setRecipientAddress(value);
        setShowInputs(false);
    };

    const howMuchSumOnChange = ({target: {value}}) => {
        let num = +value + commissionSend[eachWalletName.name]?.['TRANSACTION_COMMISSION']?.value;

        setValue('recipientCommissionSum', num);
        setValue('recipientSum', value);
    };

    const sendOutFromWallet = () => {
        const data = {};

        data.amount = watch('recipientSum');
        data.to = watch('recipientAddress');

        if (watch('email_Code2'))
            data.emailCode = watch('email_Code2')
        if (watch('sms_Code2'))
            data.smsCode = watch('sms_Code2')
        if (watch('ga_Code2'))
            data.gaCode = watch('ga_Code2')
        setIsButtonEnable(true)
        apiPost({
            url: `/wallet/send-crypto?name=${eachWalletName.name}`,
            postData: data,
        }).then(res => {
            console.log(res)
            if (res.error){
                setIsButtonEnable(false)
                return errorHandler(res.data);
            }
            if (res.data === true) {
                closeModal()
                refreshWallet()
                handleAlertOpen('Перевод выполнен успешно');
                setIsRefreshWallet(!isRefreshWallet)
            } else {
                setValidateSendWallet({
                    emailCode: res.data.emailCode,
                    smsCode: res.data.smsCode,
                    gaCode: res.data.gaCode,
                });
                setIsButtonEnable(false)
            }
        });
    };

    const openEachWalletPage = (name) => {
        setNameForEachWalletPage(name)
        setIsShowEachWalletPage(true)
        // history.push(`/wallet/${name}`);
    };

    const renderBillBlock = ({name, balance}) => {
        // if (name === 'USDR')
        //     return;
        // if (!isBtcShow) {
        //     if (name === 'BTC')
        //         return;
        // }
        return (
            <Col key={name} sm={4}>
                <BillBlock
                    name={name}
                    value={balance}
                    activeClass={getActiveClass(name, investWallet)}
                    onClick={handleChangeBillBlock}/>
            </Col>
        );
    };

    const handleChangeBillBlock = (value) => {
        setInvestWallet(value);
        reset({
            incomePay: '',
            cryproToUsdrPay: '',
            usdrCounter: '',
            cryptoCounter: '',
            IdForUser: '',
            sumForUser: '',
            recipientAddress: '',
            recipientSum: '',
            recipientCommissionSum: '',
            code: '',
            codeAuth: '',
        });
    };

    const maxValueSum = () => {
        let value = walletsData.filter(item => item.name === investWallet);
        setValue('incomePay', value[0].balance);
        getCommission(value[0].address);
        setValue(
            'cryproToUsdrPay',
            commissionSend[investWallet]['REPLENISH_RATE'].value * value[0].balance
        );
    };

    const changeInputUsdrWithCommission = ({target: {value}}) => {
        setValue(
            'incomePay',
            value / commissionSend[investWallet]['REPLENISH_RATE'].value
        );
    };

    const changeInputUsdr = (e) => {
        setValue(
            'cryproToUsdrPay',
            commissionSend[investWallet]['REPLENISH_RATE'].value * e.target.value);
    };

    const getUserInfoById = id => {
        apiGet({
            url: `/user/user-info-by-id?id=${id}`
        }).then(res => {
            if (res.error)
                return console.log(res.data)
            setUserInfoById(res.data);
        });
    };

    const handleUsdrOutCryptoChangeWithComission = ({target: {value}}) => {
        if (commissionSend === null)
            getCommission(value);

        let commiss = (value * commissionSend[investWallet]['PAYOUT_COMMISSION'].value) / 100;
        setValue('commissionCryptoCounter', `${commiss}USDR`);
        if (commiss < commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
            commiss = commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value;
            setValue('commissionCryptoCounter', `${commiss}USDR`);
        }

        const res = (value - commiss) / commissionSend[investWallet]['PAYOUT_RATE'].value;

        if (res < 0 || value < 0)
            return;
        setValue('cryptoCounter', res);
    };

    const handleCryptoFromUsdr = ({target: {value}}) => {
        if (commissionSend === null)
            getCommission(value);

        let source = (value * commissionSend[investWallet]['PAYOUT_RATE'].value) /
            ((100 - commissionSend[investWallet]['PAYOUT_COMMISSION'].value) / 100);
        let commiss = (source * commissionSend[investWallet]['PAYOUT_COMMISSION'].value) / 100;
        setValue('commissionCryptoCounter', `${commiss}USDR`);

        if (commiss < commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
            source = value * commissionSend[investWallet]['PAYOUT_RATE'].value +
                commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value;

            setValue('commissionCryptoCounter',
                `${commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value}USDR`);
        }

        if (source < 0 || value < 0)
            return;
        setValue('usdrCounter', source);
    };

    const windrawFromUsdr = () => {
        setLoaderSend(true);
        let sum = 0;

        if (investWallet)
            sum = watch('usdrCounter');

        setIsButtonEnable(true)

        apiPost({
            url: `/wallet/payout?name=${investWallet}`,
            postData: {amount: sum},
        }).then(res => {
            if (res.error){
                setIsButtonEnable(false)
                return setError(res.data);
            }
            closeModal();
            refreshWallet();
            handleAlertOpen('Вывод выполнен успешно выполнено');
            setIsRefreshWallet(!isRefreshWallet)
        }).then(() => setLoaderSend(false))
            .catch(e => {
                console.log(e);
            });
    };

    const maxUsdrValueSum = () => {
        // setValue('sumForUser', walletsData[0].balance);
        setValue('sumForUser', eachWalletName.balance);
    };

    const errorHandler = (data) => {
        let arr = []
        if (typeof data === 'string')
            arr.push(data)
        else {
            for (let key in data) {
                if(key === 'to')
                    return setError(data)
                if(key === 'amount')
                    return setError(data)
                if(key === 'sum')
                    return setError(data)
                data[key].map(item => {

                    arr.push(item)
                })
            }
        }
        setErrorFromHandler(arr)
    }

    const sendUsdrToUser = () => {
        setError({})
        const recipient_id = watch('IdForUser');
        const sum = watch('sumForUser');

        let formData = new FormData();

        if (recipient_id)
            formData.append('recipient_id', recipient_id);

        if (sum)
            formData.append('sum', sum);

        // Авторизация email / ga / sms
        if (validateSendWallet.emailCode)
            formData.append('emailCode', watch('email_Code'));

        if (validateSendWallet.smsCode)
            formData.append('smsCode', watch('sms_Code'));

        if (validateSendWallet.gaCode)
            formData.append('gaCode', watch('ga_Code'));
        setIsButtonEnable(true)
        apiPost({
            url: '/wallet/send-usdr',
            postData: formData,
        }).then(res => {
                if (res.error){
                    setIsButtonEnable(false)
                    return errorHandler(res.data);
                }
                // return setError(res.data);
                if (res.data === true) {
                    setValidateSendWallet({sms: false, email: false, ga: false});
                    handleAlertOpen('Средства успешно отправлены');
                    closeModal();
                    setIsRefreshWallet(!isRefreshWallet)
                } else {
                    setValidateSendWallet({
                        smsCode: res.data.smsCode,
                        emailCode: res.data.emailCode,
                        gaCode: res.data.gaCode,
                    });
                    setIsButtonEnable(false)
                }
            }
        );
    };

    const maxSendValueSum = () => {
        let value = walletsData.filter(item => item.name === eachWalletName.name);
        // getCommission(value[0].address);
        setValue('recipientSum', value[0].balance - commissionSend[eachWalletName.name]['TRANSACTION_COMMISSION'].value);
        setValue(
            'recipientCommissionSum',
            // commissionSend[investWallet]['REPLENISH_RATE'].value * value[0].balance
            // +value[0].balance + commissionSend[eachWalletName.name]['TRANSACTION_COMMISSION'].value * value[0].balance
            value[0].balance
        );
    }

    const maxOutUsdrValueSum = () => {
        setValue('usdrCounter', eachWalletName.balance)

        let commiss = (eachWalletName.balance * commissionSend[investWallet]['PAYOUT_COMMISSION'].value) / 100;
        setValue('commissionCryptoCounter', `${commiss}USDR`);
        if (commiss < commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
            commiss = commissionSend[investWallet]['PAYOUT_MIN_COMMISSION'].value;
            setValue('commissionCryptoCounter', `${commiss}USDR`);
        }

        const res = (eachWalletName.balance - commiss) / commissionSend[investWallet]['PAYOUT_RATE'].value;

        if (res < 0 || eachWalletName.balance < 0)
            return;
        setValue('cryptoCounter', res);
    }

    const windrawUsdrWallet = () => {
        let sum = 0;
        sum = watch('cryproToUsdrPay');
        setIsButtonEnable(true)

        apiPost({
            url: `/wallet/replenish?name=${investWallet}`,
            postData: {amount: sum},
        }).then(res => {
            if (res.error){
                setIsButtonEnable(false)
                return setError(res.data);
            }
            refreshWallet();
            closeModal();
            handleAlertOpen('Пополнение успешно выполнено');
            setIsRefreshWallet(!isRefreshWallet)
        });
    };

    const throttledUserId = useDebounce(watch('IdForUser'), 300);

    useEffect(() => {
        if (commissionSend === null)
            getCommission();
        getUserInfoById(throttledUserId);
    }, [throttledUserId]);

    // if (walletsData === null || investWalletUsdr === null) {
    //     return (<div>
    //         <Spinner styles={{ margin: 'auto', display: 'flex'}} />
    //     </div>);
    // } else {
        return (
            <div>
                {!isShowEachWalletPage &&
                <div>
                <div className="row">
                    <div className="col-12">
                        <Card className="mt-5">
                            <CardBody
                                className="p-5 flex-col flex justify-center lg:flex lg:flex-row lg:justify-start items-center">
                                <Icon name="info" size={34} color="#711313"/>
                                <div className="ml-4" style={{maxWidth: '750px'}}>
                                    Если у Вас нет криптовалюты и Вы не знаете, где можно её
                                    приобрести, мы рекомендуем Вам{' '}
                                    <a
                                        href="https://t.me/rsi_garant"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="link">
                                        RSI GARANT
                                    </a>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>

                <div className={'overflow-x-auto m-2'}>
                    {investWalletUsdr === null ? <Spinner styles={{ margin: 'auto', display: 'flex', height: 50}} /> :
                    <div
                        className={'transition delay-80 h-20 flex w-full justify-between items-center bg-white hover:bg-grey p-2 '}>
                        <div className={'flex gap-2 sm:gap-4 md:gap-8 cursor-pointer items-center w-full'}
                             onClick={() => openEachWalletPage(investWalletUsdr.name)}>
                            {/*<div className={'whitespace-nowrap cursor-pointer hidden md:block'}*/}
                            <div className={'whitespace-nowrap cursor-pointer'}
                                 onClick={() => openEachWalletPage(investWalletUsdr.name)}>
                                <img alt={investWalletUsdr.name} className={'h-6 md:h-10 sm:h-8'} src={logo}/>
                            </div>

                            <div>
                                <strong>
                                    {investWalletUsdr.name}
                                </strong>
                            </div>
                        </div>

                        <div className={'flex gap-2 sm:gap-4 mr-2'}>
                            <div className={'cursor-pointer'} onClick={() => openEachWalletPage(investWalletUsdr.name)}>
                            <div
                                className={'mr-9 sm:mr-14 md:mr-28 cursor-pointer'}
                                onClick={() => openEachWalletPage(investWalletUsdr.name)}>
                                {parseFloat(investWalletUsdr?.balance).toFixed(2)}
                            </div>
                            </div>
                            <button
                                data-for="setBalance"
                                data-tip={'Вывести'}
                                onClick={() => getToNewWallet(investWalletUsdr.name, investWalletUsdr.balance)}
                                className="items-center sm:text-text text-sm">
                                <div className='sm:hidden'>
                                    <Icon name="walletOut" size={15}/>
                                </div>
                                <div className='hidden sm:block'>
                                    <Icon name="walletOut" size={22}/>
                                </div>
                            </button>

                            <button
                                data-for="setBalance"
                                data-tip={'Пополнить'}
                                onClick={() => openModalBalanceIncome(investWalletUsdr.name, investWalletUsdr.address)}
                                className="items-center">

                                <div className='sm:hidden'>
                                    <Icon name="walletIn" size={15}/>
                                </div>
                                <div className='hidden sm:block'>
                                    <Icon name="walletIn" size={22}/>
                                </div>
                            </button>

                            <button
                                data-for="setBalance"
                                data-tip={'Отправить'}
                                onClick={() => openModalBalanceOut(investWalletUsdr.name, investWalletUsdr.balance)}
                                className="items-center sm:text-text text-sm">

                                <div className='sm:hidden'>
                                    <Icon name="walletSend" size={15}/>
                                </div>
                                <div className='hidden sm:block'>
                                    <Icon name="walletSend" size={22}/>
                                </div>
                            </button>
                        </div>
                        <ReactTooltip
                            id="setBalance"
                            globalEventOff="click"
                            className="tooltip-modal"
                            effect="solid"/>
                    </div>}

                    <div>
                        <div className='bg-bgDark my-2 p-3'><strong>Криптовалютные кошельки</strong></div>
                        {walletsData === null ? <Spinner styles={{ margin: 'auto', display: 'flex', height: 50}} /> :
                            <>
                            {walletsData.map(wallet =>
                            <div key={wallet.name}>
                                <div className={'transition delay-80 h-16 flex w-full justify-between items-center bg-white hover:bg-grey p-2 my-2'}>
                                    <div onClick={() => openEachWalletPage(wallet.name)} className='flex gap-2 sm:gap-4 md:gap-8 cursor-pointer items-center w-full'>
                                        <div
                                             className='whitespace-nowrap cursor-pointer'>
                                            <img alt={wallet.name}
                                                 className={'w-6 md:w-10 sm:w-8'}
                                                 src={wallet?.icon}/>
                                        </div>

                                        <div onClick={() => openEachWalletPage(wallet.name)}
                                             className="whitespace-nowrap cursor-pointer text-xs sm:text-sm">
                                            <strong>
                                                {wallet?.name}
                                            </strong>
                                        </div>
                                    </div>
                                        <div className={'flex gap-2 sm:gap-4 mr-2'}>
                                            <div className={'cursor-pointer'} onClick={() => openEachWalletPage(wallet.name)}>
                                            <div
                                                onClick={() => openEachWalletPage(wallet.name)}
                                                className="hidden sm:block mr-9 sm:mr-14 md:mr-28 whitespace-nowrap cursor-pointer">
                                                {wallet?.balance}
                                            </div>
                                            <div
                                                data-for="setBalance"
                                                data-tip={wallet?.balance}
                                                onClick={() => openEachWalletPage(wallet.name)}
                                                className="sm:hidden mr-9 sm:mr-14 md:mr-28 whitespace-nowrap cursor-pointer ">
                                                {parseFloat(wallet?.balance).toFixed(5)}
                                            </div>
                                            </div>

                                            <button
                                                data-for="setBalance"
                                                data-tip={'Пополнить'}
                                                onClick={() => openModalBalanceIncome(wallet.name, wallet.address)}
                                                className="items-center">
                                                <div className='sm:hidden'>
                                                    <Icon name="walletIn" size={15}/>
                                                </div>
                                                <div className='hidden sm:block'>
                                                    <Icon name="walletIn" size={22}/>
                                                </div>
                                            </button>

                                            {wallet?.isCanSend ?
                                                <button
                                                    data-for="setBalance"
                                                    data-tip={'Отправить'}
                                                    onClick={() => openModalBalanceOut(wallet.name, wallet.balance)}
                                                    className="items-center  sm:text-text text-sm">
                                                    <div className='sm:hidden'>
                                                        <Icon name="walletSend" size={15}/>
                                                    </div>
                                                    <div className='hidden sm:block'>
                                                        <Icon name="walletSend" size={22}/>
                                                    </div>
                                                </button> : <div className={'mr-4 sm:mr-6'}></div>}
                                            <CopyToClipboard
                                                onCopy={() => handleAlertOpen('Счет успешно скопирован')}
                                                text={wallet.address}>
                                                <button
                                                    // onClick={copyAddress}
                                                    data-for="setBalance"
                                                    data-tip={'Скопировать счет'}
                                                    className="items-center">
                                                    <div className='sm:hidden'>
                                                        <Icon name="copy" size={15}/>
                                                    </div>
                                                    <div className='hidden sm:block'>
                                                        <Icon name="copy" size={22}/>
                                                    </div>
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                </div>

                <ReactTooltip
                    id="setBalance"
                    globalEventOff="click"
                    className="tooltip-modal"
                    effect="solid"/>
                            </div>)}
                                {/*<ReactTooltip*/}
                                {/*    id="setBalance"*/}
                                {/*    globalEventOff="click"*/}
                                {/*    className="tooltip-modal"*/}
                                {/*    effect="solid"/>*/}
                            </>}
                    </div>
                </div>
                </div>}

                {/*Start-Modal ------------Пополнение кошелька*/}
                <Modal
                    title={'Пополнить ' + eachWalletName.name}
                    onClose={closeModal}
                    isOpen={balanceSetModal}
                    footer={
                        <CopyToClipboard
                            onCopy={() => handleAlertOpen('Счет успешно скопирован')}
                            text={eachWalletName.address}>
                            <Button color="primary">Скопировать номер счета</Button>
                        </CopyToClipboard>
                    }>
                    <div className={'font-bold'}>Номер счета</div>
                    <div className={'mb-3 flex flex-wrap'}>{eachWalletName.address}</div>
                    {eachWalletName?.address && <QRCode value={eachWalletName?.address}/>}
                </Modal>
                {/*End-Modal ------------Пополнение кошелька*/}

                {/*Start ------- Открыть окно уведомления*/}
                <Modal
                    title={alertText}
                    onClose={closeModal}
                    isOpen={alertView}
                    headerStyles={{border: 'none', color: '#000000'}}
                    footerStyles={{border: 'none', padding: '10px'}}
                    settings="alert"
                    bodyStyles={{padding: '0px'}}
                    modalStyles={{margin: 'auto'}}
                    footer={
                        <div className="flex justify-center">
                            <Col xs={'auto'}>
                                <Button onClick={closeModal} color="primary">
                                    Ок
                                </Button>
                            </Col>
                        </div>
                    }/>
                {/*End ------- Открыть окно уведомления*/}

                {/*Start-Modal ------------Отправить с <Крипта>*/}
                <Modal
                    title={`Отправить с ${eachWalletName.name}`}
                    onClose={closeModal}
                    isOpen={balanceOutModal}
                    footer={!isButtonEnable ?
                        <Button onClick={sendOutFromWallet} color="primary">Вывести</Button> : <></>}>
                    <Input
                        label="Адрес получателя"
                        name="recipientAddress"
                        onChange={recipientAddressOnChange}
                        register={register}/>

                    <div className='error mb-3'>
                        {error?.to && error.to[0]}
                    </div>

                    {showInputs && recipientAddress.length >= 15 &&
                        <>
                            <div className={'flex'}>
                                <Input
                                    fieldClass={'w-full mt-auto mb-0'}
                                    label="Сумма отправки"
                                    name="recipientSum"
                                    onChange={howMuchSumOnChange}
                                    register={register}/>

                                <div className={'mt-auto mb-0'}>
                                    <Button
                                        className={'ml-2 h-10'}
                                        onClick={maxSendValueSum}
                                        color="primary">Все</Button>
                                </div>
                            </div>
                                <div className='error mt-1 mb-3'>
                                    {/*<button onClick={() => console.log(error)}>show</button>*/}
                                    {error?.amount && error.amount[0]}
                                </div>
                            <div className={(watch('recipientSum') < 0) ? 'mb-3 mt-1 text-red' : 'mb-3 mt-1'}>
                                <strong>На счете:</strong> {eachWalletName?.balance}
                            </div>

                            <Input
                                label="С учетом комиссии"
                                name="recipientCommissionSum"
                                register={register}/>

                            {showInputs &&
                                <div className='mb-2'>
                                    Приблизительная
                                    комиссия: {commissionSend[eachWalletName?.name]['TRANSACTION_COMMISSION']?.value} {eachWalletName?.name}
                                </div>}
                            <div className="error mt-1 mb-3">
                                Внимание! Комиссия за транзакцию является ПЛАВАЮЩЕЙ и зависит от
                                цены и обьема "газа". Окончательная цена будет скорректирована
                                после совершения транзакции.
                            </div>

                            {validateSendWallet.emailCode &&
                                <Input
                                    label='Email - код'
                                    name="email_Code2"
                                    register={register}/>}

                            {validateSendWallet.smsCode &&
                                <Input
                                    label='Sms - код'
                                    name="sms_Code2"
                                    register={register}/>}

                            {validateSendWallet.gaCode &&
                                <Input
                                    label='Google authenticator - код'
                                    name="ga_Code2"
                                    register={register}/>}

                            {errorFromHandler === null ? <></> :
                                <>
                                    {errorFromHandler.map((item, i) =>
                                        <div className='error mb-2' key={i}>
                                            {item}
                                        </div>
                                    )}
                                </>}
                        </>}
                </Modal>
                {/*End-Modal ------------Отправить с <Крипта>*/}

                {/*Start-Modal ------------Пополнить USDR*/}
                <Modal
                    title={'Пополнить USDR'}
                    onClose={closeModal}
                    isOpen={usdrBalanceOutModal}
                    footer={!isButtonEnable ? <Button onClick={windrawUsdrWallet}
                                    color="primary">Пополнить</Button> : <></>}>
                    <Row>
                        <Col xs={12}>
                            <div className="text-lg font-medium mb-2">Куда вывести</div>
                        </Col>
                        {availableWalletsToReplenish !== null && availableWalletsToReplenish.map(renderBillBlock)}
                    </Row>
                    {investWallet &&
                        <>
                            <div className={'flex'}>
                                <Input
                                    fieldClass={'w-full mt-auto mb-0'}
                                    label={investWallet}
                                    name="incomePay"
                                    onChange={changeInputUsdr}
                                    register={register}/>
                                <div className={'mt-auto mb-0'}>
                                    <Button className={'ml-2 h-10'} onClick={maxValueSum} color="primary">Все</Button>
                                </div>
                            </div>

                            <span>Комиссия -{' '}
                                {commissionSend[investWallet]['REPLENISH_COMMISSION'].value}%</span>
                            <Input fieldClass={'my-5'}
                                   label='USDR'
                                   onChange={changeInputUsdrWithCommission}
                                   placeholder='Количество USDR'
                                   name="cryproToUsdrPay"
                                   register={register}/>
                        </>}
                    {error?.amount && <div className="error">{error?.amount}</div>}
                </Modal>
                {/*End-Modal ------------Пополнить USDR*/}

                {/*Start-Modal ------------Отправить USDR*/}
                <Modal
                    title={'Отправить ' + eachWalletName.name}
                    onClose={closeModal}
                    isOpen={balanceOutUsdrModal}
                    footer={!isButtonEnable ?
                        <Button onClick={sendUsdrToUser} color="primary">Отправить</Button> : <></>
                    }>
                    {userInfoById && (
                        <NotifyBlock classList="mb-4 _color-primary">
                            Получатель: {userInfoById}
                        </NotifyBlock>)}

                    <Input
                        label="ID получателя"
                        type="number"
                        name="IdForUser"
                        register={register}/>

                    <div className={'flex'}>
                        <Input
                            fieldClass={'w-full mt-auto mb-0'}
                            label={'USDR'}
                            type="number"
                            name="sumForUser"
                            register={register}/>
                        <div className={'mt-auto mb-0'}>
                            <Button className={'ml-2 h-10'} onClick={maxUsdrValueSum} color="primary">Все</Button>
                        </div>
                    </div>
                    <div className='error mt-1 mb-3'>
                        {error?.sum && error.sum[0]}
                    </div>

                    <div className="col-auto mt-1 mb-3">
                        {/*Доступно: {parseFloat(walletsData?.[0]?.balance).toFixed(6)}*/}
                        Доступно: {eachWalletName.balance}
                    </div>

                    {validateSendWallet.emailCode &&
                        <Input
                            label='Email код'
                            name="email_Code"
                            register={register}/>}

                    {validateSendWallet.smsCode &&
                        <Input
                            label='Sms код'
                            name="sms_Code"
                            register={register}/>}

                    {validateSendWallet.gaCode &&
                        <Input
                            label='Google authenticator код'
                            name="ga_Code"
                            register={register}/>}

                    {errorFromHandler === null ? <></> :
                        <>
                            {errorFromHandler.map((item, i) =>
                                <div className='error mb-2' key={i}>
                                    {item}
                                </div>
                            )}
                        </>}

                </Modal>
                {/*End-Modal ------------Отправить c USDR*/}

                {/*Start-Modal ------------Вывести c USDR*/}
                <Modal
                    title={'Вывести с USDR'}
                    onClose={closeModal}
                    isOpen={withdrawBalanceUsdrModal}
                    footer={
                        !investWallet ? <></> : !isButtonEnable ?
                                <Button onClick={windrawFromUsdr} color="primary">Вывести</Button> :
                            <></>

                        // (loaderSend ? <Spinner size={40}/> :
                        //         <Button onClick={windrawFromUsdr} color="primary">Вывести</Button>
                        // )
                }>
                    <Row>
                        <Col xs={12}>
                            <div className="text-lg font-medium mb-2">Куда вывести</div>
                        </Col>
                        {availableWalletsToPayout !== null && availableWalletsToPayout.map(renderBillBlock)}
                    </Row>
                    {investWallet !== null &&
                        <>
                            <div className={'flex'}>
                                <Input
                                    fieldClass={'w-full mt-auto mb-0'}
                                    label={'USDR'}
                                    type="number"
                                    name="usdrCounter"
                                    onChange={handleUsdrOutCryptoChangeWithComission}
                                    placeholder='Количество USDR'
                                    register={register}/>
                                <div className={'mt-auto mb-0'}>
                                    <Button className={'ml-2 h-10'} onClick={maxOutUsdrValueSum}
                                            color="primary">Все</Button>
                                </div>
                            </div>

                            <div className={'mb-3'}>
                                На счете {eachWalletName.balance} USDR
                            </div>

                            {watch('commissionCryptoCounter') && (
                                <span>Комиссия - {watch('commissionCryptoCounter')}</span>
                            )}
                            <Input
                                label={investWallet}
                                type="number"
                                name="cryptoCounter"
                                onChange={handleCryptoFromUsdr}
                                placeholder={`Количество ${investWallet}`}
                                register={register}/>
                            {error?.amount && <div className="error">{error.amount}</div>}
                        </>}
                </Modal>
                {/*End-Modal ------------Вывести c USDR*/}

                {isShowEachWalletPage &&
                    <EachWalletPage
                        // balanceOutUsdrModal={balanceOutUsdrModal}
                        // balanceOutModal={balanceOutModal}
                        isRefreshWallet={isRefreshWallet}
                    getToNewWallet={getToNewWallet}
                        handleAlertOpen={handleAlertOpen}
                        nameForEachWalletPage={nameForEachWalletPage}
                        setIsShowEachWalletPage={setIsShowEachWalletPage}
                        openModalBalanceIncome={openModalBalanceIncome}
                        openModalBalanceOut={openModalBalanceOut}/>}


            </div>
        );
    // }
};

export default WalletPageUpdate;
