import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { apiGet } from '../../../../../api';
import { TreeGeneration } from './treeGeneration';

export const LinearTreeGenerations = ({
  parentIndex,
  openUser,
  data: { id,
    // qualification,
    percent, name, totalInvest,totalSmartHigh ,totalSamrtLow, avatar, children, isHasChildren, qualifications},
}) => {
  const ref = useRef(null);
  const [childrenData, setChildrenData] = useState([]);

  const toggleChild = () => {
    apiGet({
      url: name === 'Остальные' ? '/linear/other' : `/linear/children?id=${id}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка в загрузке children');
          return;
        }
        if (!res.error) {
          setChildrenData([...res.data.children]);
          ref.current.classList.toggle('w-0');
          ref.current.classList.toggle('h-0');
        }
      }
    });
  };

  const renderChild = (i, ind) => {
    if (i.name !== 'Остальные') {
      return (
        <div
          key={ind}
          className={cx('flex flex-col relative pr-6 mt-6', {
            _last: !parentIndex && ind === 2,
          })}
        >
          <LinearTreeGenerations
            openUser={openUser}
            parentIndex={parentIndex + 1}
            data={i}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    if (children) {
      setChildrenData(children);
    }
  }, [children]);

  return (
    <>
      <div
        className={cx('relative node-wrap', {
          '_has-child': isHasChildren,
        })}
      >
        <TreeGeneration
          id={id}
          // qualification={qualification}
          percent={percent}
          name={name}
          totalInvest={totalInvest}
          totalSamrtLow={totalSamrtLow}
          totalSmartHigh={totalSmartHigh}
          avatar={avatar}
          totalIndex={parentIndex}
          hasChild={Boolean(childrenData)}
          toggleChild={() => toggleChild(name)}
          onClick={openUser}
          qualifications={qualifications}
        />
      </div>

      {childrenData && (
        <div
          ref={ref}
          className={cx(
            'flex relative child-wrap flex-col child-inner-wrap pl-7 overflow-hidden ',
            {
              'flex-col child-inner-wrap pl-7  overflow-hidden': parentIndex,
              'w-0 h-0': !children?.length,
            }
          )}
        >
          {childrenData?.map(renderChild)}
        </div>
      )}
    </>
  );
};
LinearTreeGenerations.propTypes = {
  parentIndex: PropTypes.number,
  openUser: PropTypes.func,
  data: PropTypes.object,
};
