import pluralize from 'pluralizers/ru';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { COLORS } from '../../../../../global/colors';
import { Icon } from '../../../../common/icon';

export const ScoresBlock = ({ text, usdr, people }) => {
  const pluralizerSettings = {
    zero: 'пользователей',
    one: 'пользователь',
    few: 'пользователя',
    many: 'пользователей',
  };

  return (
    <Card>
      <CardBody className="xs:p-2 md:p-6">
        <Row className="items-center">
          <div className="relative col-sm-6">
            <div className="w-16 h-16 rounded bg-bgDark absolute top-1/2 transform -translate-y-1/2" />

            <p className="relative z-10 pl-4">
              Баллы в {text} ветке
              <span className="text-2xl font-medium block text-main-color">
                {usdr} USDR
              </span>
            </p>
          </div>

          <div className="relative col-sm-6">
            <div className="left-8 w-16 h-16 rounded bg-bgDark absolute top-1/2 transform -translate-y-1/2" />

            <div className="relative z-10">
              <Row className="items-center">
                <Col xs="auto pr-0">
                  <Icon name="people" size={30} color={COLORS.purple} />
                </Col>

                <Col>
                  <p>
                    В {text} ветке
                    <b className="block">
                      {people} {pluralize(pluralizerSettings, people)}
                    </b>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};
ScoresBlock.propTypes = {
  text: PropTypes.string,
  usdr: PropTypes.string,
  people: PropTypes.any,
};
