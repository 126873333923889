import { AsYouType } from 'libphonenumber-js';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import videoPoster2 from '../../../../assets/images/video/2.jpg';
import videoPoster3 from '../../../../assets/images/video/3.3.jpg';
import videoPoster4 from '../../../../assets/images/video/4.4.jpg';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { Icon } from '../../../common/icon';
import { Button } from '../../../common/ui/button';
import { Modal } from '../../../common/ui/modal';
import { FastLaunchLayout } from '../../../layouts/fastLaunch';
import '../common.scss';

export const FastLaunchPage2 = () => {
  const screenWidth = window.screen.width;

  const { referral } = useUrl();
  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [moreDetailed, setMoreDetailed] = useState(false);

  const { response: curatorData } = useFetch({
    url: `/auth/curator?ref=${referral}`,
  });

  const modalRef = useRef(null);

  const { windowWidth } = useWindowSize();

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.height = `${
        modalRef.current.clientWidth * (9 / 16)
      }px`;
    }
  }, [modalRef, windowWidth]);

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  return (
    <FastLaunchLayout>
      <Col xs={12}>
        <Row className="justify-center">
          <Col lg={9} xl={7}>
            <p className="text-sm sm:text-lg lg:text-xl text-center leading-normal mb-10">
              Итак, RSI Capital - это платформа альтернативных инвестиционных
              решений, созданная в 2018 году.
              <br />
              <br />
              RSI Capital сегодня - это международная компания, ведущая
              лицензированную деятельность в сфере криптовалюты и управления
              частными капиталами наших клиентов.
              <br />
              <br />
              Исходя из потребностей современного рынка, команда дипломированных
              инвестиционных экспертов RSI разработала несколько авторских
              продуктов. Именно о них мы и поговорим.
            </p>
          </Col>
        </Row>
      </Col>

      <Col xs={12}>
        <Row>
          <Col md={6} lg={4} className="mb-4">
            <Card className="p-3 h-full flex flex-col justify-between">
              <div>
                <button
                  onClick={() =>
                    openVideo(
                      'https://www.youtube.com/embed/Wqr0y3HRD0k?autoplay=1'
                    )
                  }
                  className="mb-3 rounded-lg overflow-hidden relative"
                >
                  <img alt="" src={videoPoster2} />

                  <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purpleDark w-16 h-12 rounded-xl flex items-center justify-center">
                    <Icon name="playButton" size={24} color="#fff" />
                  </span>
                </button>

                <ul>
                  <li>
                    Вход: <b>от 3000$</b>
                  </li>
                  <li>
                    Пополнение/реинвестирование: <b>от 100$</b>
                  </li>
                  <li>
                    Комиссия на ввод: <b>2%</b>
                  </li>
                  <li>
                    Комиссия на вывод: <b>0%</b>
                  </li>
                  <li>
                    Распределение прибыли: <b>50 на 50</b>
                  </li>
                  <li>
                    Целевая доходность: <b>от 55% годовых для клиента</b>
                  </li>
                  <li>
                    Доходность по итогам 2020: <b>87% для клиента</b>
                  </li>
                </ul>
              </div>

              <Link
                to={`/registration?referral=${referral ? referral : ''}`}
                className="mt-4"
              >
                <Button color="primary" className="w-full">
                  Инвестировать в SMART
                </Button>
              </Link>
            </Card>
          </Col>

          <Col md={6} lg={4} className="mb-4">
            <Card className="p-3 h-full flex flex-col justify-between">
              <div>
                <button
                  onClick={() =>
                    openVideo(
                      'https://www.youtube.com/embed/1cjSxxu10ZA?autoplay=1'
                    )
                  }
                  className="mb-3 rounded-lg overflow-hidden relative"
                >
                  <img alt="" src={videoPoster3} />

                  <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purpleDark  w-16 h-12 rounded-xl flex items-center justify-center">
                    <Icon name="playButton" size={24} color="#fff" />
                  </span>
                </button>

                <ul>
                  <li>
                    <b>3 000$</b> - доход 2% в месяц
                  </li>
                  <li>
                    <b>5 000$</b> - доход 2.25% в месяц
                  </li>
                  <li>
                    <b>9 000$</b> - доход 2.5% в месяц
                  </li>
                  <li>
                    <b>от 15 000$</b> - доход 3% в месяц
                  </li>
                  <li>
                    <b>от 100 000$</b> - возможно обсуждение индивидуальных
                    условий.
                  </li>
                  <li>
                    <b>Криптовалютный обменник GARANT:</b>{' '}
                    <a
                      style={{ color: '#711313' }}
                      target="blank"
                      href="https://t.me/RSI_garantBot"
                    >
                      RSI GARANT Bot
                    </a>
                  </li>
                </ul>
              </div>

              <Link
                to={`/registration?referral=${referral ? referral : ''}`}
                className="mt-4"
              >
                <Button color="primary" className="w-full">
                  Инвестировать в Garant
                </Button>
              </Link>
            </Card>
          </Col>

          <Col md={6} lg={4} className="mb-4">
            <Card className="p-3 h-full flex flex-col justify-between">
              <div>
                <button
                  onClick={() =>
                    openVideo(
                      'https://www.youtube.com/embed/XYW3yFhVtPg?autoplay=1'
                    )
                  }
                  className="mb-3 rounded-lg overflow-hidden relative"
                >
                  <img alt="" src={videoPoster4} />

                  <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purpleDark  w-16 h-12 rounded-xl flex items-center justify-center">
                    <Icon name="playButton" size={24} color="#fff" />
                  </span>
                </button>

                <p className="sm:text-md text-text">
                  {/* <ul>
                  <li>
                    <b>3 000$</b> - доход 2% в месяц
                  </li>
                  <li>
                    <b>5 000$</b> - доход 2.25% в месяц
                  </li>
                  <li>
                    <b>9 000$</b> - доход 2.5% в месяц
                  </li>
                  <li>
                    <b>от 15 000$</b> - доход 3% в месяц
                  </li>
                  <li>
                    <b>от 100 000$</b> - возможно обсуждение индивидуальных
                    условий.
                  </li>
                  <li>
                    <b>Криптовалютный обменник GARANT:</b>{' '}
                    <a
                      style={{ color: '#711313' }}
                      target="blank"
                      href="https://t.me/RSI_garantBot"
                    >
                      RSI GARANT Bot
                    </a>
                  </li>
                </ul> */}
                  Клуб RSI - это возможность обучаться и зарабатывать с ведущими
                  аналитиками и трейдерами RSI по подписке.
                  <br />
                  <br />
                  Цена подписки: <br />
                  67$ за месяц, <br />
                  180$ за 3 месяца, <br />
                  300$ за 6 месяцев, <br />
                  <b> 450$ за 12 месяцев доступа.</b>
                  {!moreDetailed && (
                    <>
                      <br />
                      <br />
                      <p
                        className="text-main-color cursor-pointer"
                        onClick={() => setMoreDetailed(true)}
                      >
                        Подробнее..
                      </p>
                    </>
                  )}
                  {moreDetailed && (
                    <>
                      <br />
                      <br />
                      Обучающие курсы:
                      <br />
                      <br />
                      <b>Разумный инвестор*</b> - базовая финансовая
                      грамотность.
                      <br />
                      <b>Портфельный инвестор*</b> - курс по составлению
                      криптопортфеля "в долгую".
                      <br />
                      <b>Активный инвестор*</b> - обучающий материал по
                      самостоятельной торговле на криптовалютном рынке.
                      <br />
                      <br />
                      Сообщество:
                      <br />
                      <br />
                      <b>Доступ к закрытому TG каналу**</b>
                      <span className="italic">
                        (сигналы, разбор криптопортфеля, рекомендации по покупке
                        и продаже активов, закрытые пресейлы интересных токенов,
                        прямые эфиры с трейдерами, общение, аналитика рынков)
                      </span>
                      <br />
                      <b>Закрытые мероприятия**</b>
                      <span className="italic">
                        ("тусовка трейдеров", "инвесторский weekend", итд)
                      </span>
                      <br />
                      <br />
                      <span className="italic">
                        {' '}
                        *Материалы доступны на любом пакете подписки
                        <br />
                        **Доступно{' '}
                        <b>только членам клуба на годовом абонементе (450$)</b>
                      </span>
                      <br />
                      <br />
                      <p
                        className="text-main-color cursor-pointer"
                        onClick={() => setMoreDetailed(false)}
                      >
                        Скрыть
                      </p>
                    </>
                  )}
                </p>
              </div>

              <Link
                to={`/registration?referral=${referral ? referral : ''}`}
                className="mt-4"
              >
                <Button color="primary" className="w-full">
                  Купить подписку RSI club
                </Button>
              </Link>
            </Card>
          </Col>
        </Row>
        <div className="text-center">
          <p className="text-center">
            Все интересующие Вас вопросы вы всегда можете задать вашему
            куратору:
            <br />
            <span>
              {curatorData?.data?.last_name} {curatorData?.data?.first_name}{' '}
              {curatorData?.data?.patronymic}
            </span>
          </p>

          <div className="row -mx-2 justify-center mt-3 social-links">
            {curatorData?.data?.phone && (
              <div className="px-2 col-auto mb-3">
                <a
                  className="link flex items-center no-underline"
                  href={`tel:${curatorData?.data?.phone}`}
                >
                  <span className="mr-2 phone flex items-center justify-center icon">
                    <Icon color="#fff" size={16} name="phoneLogo" />
                  </span>

                  <span className="hidden lg:block text-text">
                    {new AsYouType().input(`+${curatorData?.data?.phone}`)}
                  </span>
                </a>
              </div>
            )}

            {curatorData?.data?.tg && (
              <div className="px-2 col-auto mb-3">
                <a
                  className="link tg flex items-center justify-center icon"
                  href={`https://telegram.me/${
                    curatorData?.data?.tg && (curatorData?.data?.tg).substr(1)
                  }`}
                >
                  <Icon color="#fff" size={16} name="telegramFill" />
                </a>
              </div>
            )}
            {curatorData?.data?.fb && (
              <div className="px-2 col-auto mb-3">
                <a
                  className="link fb flex items-center justify-center icon"
                  href={`${curatorData?.data?.fb}`}
                >
                  <Icon color="#fff" size={16} name="facebookLogo" />{' '}
                </a>
              </div>
            )}
            {curatorData?.data?.sk && (
              <div className="px-2 col-auto mb-3">
                <a
                  className="link sk flex items-center justify-center icon"
                  href={`Skype:${curatorData?.data?.sk}?add`}
                >
                  <Icon color="#fff" size={16} name="skypeLogo" />{' '}
                </a>
              </div>
            )}
            {curatorData?.data?.vk && (
              <div className="px-2 col-auto mb-3">
                <a
                  className="link vk flex items-center justify-center icon"
                  href={`${curatorData?.data?.vk}`}
                >
                  <Icon color="#fff" size={16} name="vkFill" />{' '}
                </a>
              </div>
            )}
          </div>
        </div>

        <Row className="justify-center mt-10 lg:mt-16 xl:mt-20">
          <Link to={`/fast-launch/step-3?referral=${referral ? referral : ''}`}>
            <Button className="w-full" color="primary">
              Далее
            </Button>
          </Link>
        </Row>
      </Col>

      <Modal
        isOpen={isVideoVisible}
        modalStyles={{
          maxWidth: 1280,
          width: screenWidth < 600 ? '95%' : '60%',
          margin: 'auto',
        }}
        onClose={closeVideo}
        bodyStyles={{ padding: 6, height: '100%' }}
      >
        <div ref={modalRef}>
          {videoUrl && (
            <iframe
              className="modal-video"
              src={videoUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>
    </FastLaunchLayout>
  );
};
