import cx from 'classnames';
import React, {useState} from 'react';
import { useFetch } from '../../../hooks/useFetch';
import Spinner from '../../common/spinner';
import { Icon } from '../icon';
import { Modal } from '../ui/modal';
import { TableUsersVal } from './TableUsersVal';
import './index.scss';

// const tableLength = table.body.length - 1

export const PersonalProgress = () => {
  const { response: linearTableData } = useFetch({ url: '/linear/levels' });
  // const { response: popData} = useFetch({ url: '/linear/partners-on-level?levelPercent=6' });

  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const getData = (obj, name) => {
    setModalData({ ...obj, name: name });
    setModalVisible(true);
  };

  const renderTableHead = (i, index) => {
    return (
      <th className={index === 0 ? 'first-table-th' : ''} key={i}>
        {i}
        {index ? '' : ''}
      </th>
      // <th className={index === 0 ? 'first-table-th' : ''} key={i}>
      //   {i}
      //   {''}
      //   {index > 0 ? (
      //     <>
      //       {/*<span>*/}
      //       {/*/ </span> {parseInt(i) / 3}*/}
      //     </>
      //   ) : (
      //     ''
      //   )}
      //   {index ? '' : ''}
      // </th>
    );
  };
 

  const renderIcon = () => {
    return <Icon name={'check'} size={12} color={'#007100'} className="mr-2" />;
  };

  let procents = [];
  linearTableData?.data?.map(i => {
    for (let item in i) {
      if (item === 'name')
        console.log('0');
      else
        procents.push(item);
    }
  });
  procents = [...new Set(procents)];

  // procents = procents.map(item => (item = parseInt(item)));
  // procents.sort();
  // procents = procents.filter(
  //   (item, index) => item != procents[index + 1] && isNaN(item) == false
  // );
  // procents.sort((a, b) => a - b);
  // procents = procents.map(item => (item = item.toString()));
  const table = {
    head: ['УСЛОВИЕ', ...procents],
  };

  const renderTableVal = (obj, index, name) => {
    return (
      <td
        key={Math.random()}
        className={cx({
          _active: obj.isDone,
          'font-normal _wait': !obj.isDone,
        })}
      >
        <div className="flex items-center">
          {obj.isDone && obj.need && renderIcon()}
          {obj.need || '-'}
          {obj.need && (
            <button
              onClick={() => getData(obj, name)}
              className="ml-3 btn-default cursor-pointer w-4 h-4 rounded flex justify-center items-center"
            >
              <Icon name="question" color="#777" size={9}/>
            </button>
          )}
        </div>
      </td>
    );
  };


  return (
    <>
      <div className="overflow-x-auto">
        {linearTableData ? (
          <table className="table">
            <thead>
              <tr>{table.head.map(renderTableHead)}</tr>
            </thead>

            <tbody>
              {linearTableData?.data?.map((i, index) => (
                <tr key={i.name}>
                  <td className="name-table">{i.name}</td>
                  {i.name != 'Количество партнеров'
                    ? procents.map(item =>renderTableVal(i[item], index, i.name)): procents.map((item, key) =>  <TableUsersVal obj={i[item]} tkey={key} key={key}req={item}/> )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Spinner styles={{ margin: 'auto' }} />
        )}
      </div>

      <Modal
        title="Статистика уровня"
        onClose={handleCloseModal}
        isOpen={modalVisible}
        footer={
          <button
            className="btn btn-primary btn-size-sm"
            onClick={handleCloseModal}
          >
            Понятно
          </button>
        }
      >
        <ul>
          <li>
            По критерию <b>"{modalData.name}"</b> набрано {modalData.value} из{' '}
            {modalData.need}
          </li>
        </ul>

        <div className="h-5 rounded-full w-full relative bg-bg mt-3">
          <div
            className="absolute top-0 rounded-full left-0 bottom-0 bg-purple flex items-center justify-center text-xs"
            style={{
              width: `${
                modalData.need <= modalData.value
                  ? 100
                  : ((+modalData.value * 100) / +modalData.need).toFixed(0)
              }%`,
              color: '#ffffff',
            }}
          >
            {modalData.need <= modalData.value
              ? '100'
              : ((+modalData.value * 100) / +modalData.need).toFixed(0)}
            %
          </div>
        </div>
      </Modal>
    </>
  );
};
