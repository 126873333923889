import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Container,CardBody, Row } from 'reactstrap';
import avatarLogo from '../../../../assets/images/avatar.svg';
import { Icon } from '../../../common/icon';
import { Button } from '../../../common/ui/button';
import { Modal } from '../../../common/ui/modal';
import './styles.scss';
import PropTypes from 'prop-types';

export const HomePage = () => {
  const profile = useSelector(s => s.user.profile);
  const [alertView, setAlertView] = useState(false);
  const [alertCodeView, setAlertCodeView] = useState(false);

  const handleAlertOpen = () => setAlertView(true);
  const handleAlertClose = () => setAlertView(false);
  const handleCodeAlertOpen = () => setAlertCodeView(true);
  const handleCodeAlertClose = () => setAlertCodeView(false);

  const handleCopy = () => {
    copy(`https://lk.rsi-capital.com/fast-launch/step-1?referral=${profile.ref}`);
    handleAlertOpen();
  };
  const handleCopyCode = () => {
    copy(profile.ref);
    handleCodeAlertOpen();
  };

  return (
    <Container fluid>
      <div className="sm:text-2xl text-lg my-8 font-medium">Главная</div>

      <Card className="mb-8 p-4">
        <Row className="items-center">
          <Col >
            <Row>
              <Col xs="auto pr-0">
                <img
                  src={profile?.avatar || avatarLogo}
                  className="sm:w-20 sm:h-20 w-10 h-10 rounded-md"
                />
              </Col>

              <Col className="flex flex-col justify-between">
                <div className="text-xs text-main-color">
                  ID {profile?.user_id}
                </div>
                <div>
                  <div className="xl:text-2xl md:text-lg text-md font-medium">
                    {profile?.last_name} {profile?.first_name}{' '}
                    {profile?.patronymic}
                  </div>

                  <div className="price text-xl">
                    {(+profile?.balance).toFixed(2)} USDR
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="auto mr-3">
            <Button onClick={handleCopy} color="primary" className=" mr-3 my-3  h-full p-3">
              <Icon color="#fff" name="copy" size={20} />
              <span> Реферальная ссылка</span>
            </Button>
         
         
            <Button onClick={handleCopyCode} color="primary" className="h-full my-3 p-3">
              <Icon color="#fff" name="copy" size={20} />
              <span> Реферальный код</span>
            </Button>
          </Col>
        </Row>
      </Card>

      <Card>
        <CardBody className="card-body">
          <Row className="xl:flex-none xl:flex-row flex flex-col-reverse items-center">
            <Col xs={12}>
              <p className="sm:text-xl text-md ">Уважаемый партнёр платформы!</p>
              <br />
              <p className="sm:text-xl text-md ">
                Добро пожаловать в сердце вашего бизнеса - личный кабинет RSI PARTNERS! 
                <br /> 
                Мы собрали все необходимые инструменты для работы в одном месте, и теперь вы, как и тысячи наших партнёров, имеете возможность использовать их в своих интересах!
                <br /> 
                Все разделы кабинета - интуитивно понятны и юзабильны, и, чтобы разобраться в том, как это встроено, вам понадобится всего несколько минут. 
                <br /> 
                Ваши предложения и пожелания вы всегда можете направить в нашу службу поддержки в соответствующем разделе. 
                <br /> 
                С уважением, команда RSI PARTNERS.
              </p>

            </Col>

            <Col xs={12}>
              <img src="" />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Modal
        title="Реферальная ссылка успешно скопирована"
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
      <Modal
        title="Реферальный код успешно скопирован"
        onClose={handleCodeAlertClose}
        isOpen={alertCodeView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleCodeAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </Container>
  );
};

HomePage.propTypes = {
  existBinary: PropTypes.bool,
  existLinear: PropTypes.bool,
};

export default HomePage;
