import React, { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import Spinner from '../../common/spinner';
import { Button } from '../../common/ui/button';
import { apiGet, apiPost } from '../../../api';
import { Col } from 'reactstrap';
import { Modal } from '../../common/ui/modal';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { updateWalletCount } from '../../../store/user/actions';
import { useDispatch } from 'react-redux';
import { courseDesList } from '../../../global/course';
import qrCodeImg from '../../../assets/images/course2QrCode.jpeg';
import bulatImg from '../../../assets/images/bulat.png';

export const CoursesLevel2 = () => {
  const { response, loading, setResponse } = useFetch({
    url: '/education/courses',
  });

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const [qrCodePayModalVisible, setQrCodePayModalVisible] = useState(false);

  const [isSuccessPaidAlertView, setIsSuccessPaidAlertView] = useState(false);
  const [successPaidText, setSuccessPaidText] = useState(false);

  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [productData, setProductData] = useState(null);

  const handleAlertOpen = text => {
    setAlertView(true);
    setAlertText(text);
  };
  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  const confirmPayment = async () => {
    const res = await apiPost({
      url: '/education/buy',
      postData: {
        type: productData.type,
      },
      headerList: {
        AppLoading: true,
      },
    });

    let text = 'Курс успешно куплен';

    if (res.error) {
      text = `Ошибка. ${res.data}`;
      console.error(res);
    } else {
      const newData = response.data.map(item => ({
        ...item,
        isActive: item.type === productData.type ? true : item.type,
      }));

      setResponse({ ...response, data: newData });
      getBalanceWallet();
    }

    handleAlertClose();
    setSuccessPaidText(text);
    setIsSuccessPaidAlertView(true);
  };

  const renderItem = item => {
    const onClick = () => {
      setProductData(item);
      handleAlertOpen(`Приобрести "${item.name}" за ${item.price} USDR?`);
    };

    const payWithCard = () => {
      setQrCodePayModalVisible(true);
    };

    return (
      <div className="col-md-6 col-lg-4" key={item.type}>
        <div className="card">
          <div className="card-body">
            <img src={bulatImg} alt="" className="mb-4" />

            <p className="text-sm">{item.price} USDR</p>
            <p className="text-2xl font-medium">{item.name}</p>
            <p className="mt-1">Дата: с 10.01.2022</p>

            <p className="mt-2">{courseDesList[item.type]?.des}</p>

            <div className="mt-1">
              <Link
                to={`/courses-level2/${item.type}`}
                className="text-main-color underline"
              >
                Подробнее
              </Link>
            </div>

            <div className="mt-3 text-lg">
              Осталось мест {item.freePlaceCount} из {item.totalPlaceCount}
            </div>

            <div className="mt-6 row">
              <div className="col-auto">
                {item.isActive ? (
                  <a
                    href={item.inviteLink}
                    target="_blank"
                    className="btn btn-primary"
                    rel="noreferrer"
                  >
                    Начать обучение
                  </a>
                ) : (
                  item.freePlaceCount > 0 && (
                    <div className="row">
                      <div className="col-auto mt-2">
                        <Button color="primary" onClick={onClick}>
                          Приобрести
                        </Button>
                      </div>

                      <div className="col-auto mt-2">
                        <Button color="primary" onClick={payWithCard}>
                          Оплатить картой или со счета
                        </Button>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getBalanceWallet = () => {
    apiGet({
      url: '/wallet/usdr',
    }).then(res => {
      dispatch(
        updateWalletCount(+res.data?.balance)
      );
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row my-8">
          <div className="col-auto">
            <Button
              color={
                location.pathname === '/business-package' ? 'primary' : 'gray'
              }
              onClick={() => history.push('/business-package')}
            >
              Уровень 1
            </Button>
          </div>

          <div className="col-auto">
            <Button
              color={
                location.pathname === '/courses-level2' ? 'primary' : 'gray'
              }
              onClick={() => history.push('/courses-level2')}
            >
              Уровень 2
            </Button>
          </div>
        </div>

        <div className="mt-8 row">{response.data.map(renderItem)}</div>
      </div>

      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="row justify-between p-3">
            <Col xs={'auto'}>
              <Button onClick={confirmPayment} color="primary">
                Да
              </Button>
            </Col>
          </div>
        }
      />

      <Modal
        title={successPaidText}
        onClose={() => setIsSuccessPaidAlertView(false)}
        isOpen={isSuccessPaidAlertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center p-3">
            <Button
              onClick={() => setIsSuccessPaidAlertView(false)}
              color="primary"
            >
              ОК
            </Button>
          </div>
        }
      />

      <Modal
        isOpen={qrCodePayModalVisible}
        title="Оплатить картой или со счета"
        onClose={() => setQrCodePayModalVisible(false)}
      >
        <img
          src={qrCodeImg}
          className="mb-6"
          style={{
            width: 280,
            height: 280,
          }}
        />
        <p>
          Стоимость 148,000 рублей
          <br />
          <br />
          Для оплаты курса с помощью карты или со счета ООО/ИП
          <br />
          <br />
          Вам необходимо навести камеру телефона на Qr-код, появится всплывающее
          окно со ссылкой, перейдите по ссылке и выберете удобный для Вас банк
          для оплаты, далее следуйте инструкциям Вашего банка. (в случае
          необходимости указания назначения платежа укажите: "Оплата
          образовательного мастер-класса RSI ФИО");
          <br />
          <br />
          Указать стоимость 148,000 рублей
          <br />
          <br />
          Также оплатить можно в мобильном приложении Вашего банка, выбрав
          раздел оплаты по Qr-коду. Чек по оплате необходимо отправить в
          telegram по номеру +79272447719. Комиссия за платеж не взимается.
          возможно оплатить с кредитной карты или карты рассрочки.
        </p>
      </Modal>
    </>
  );
};
