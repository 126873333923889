import { AsYouType } from 'libphonenumber-js';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import videoPoster5 from '../../../../assets/images/video/5.jpg';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { Icon } from '../../../common/icon';
import { Button } from '../../../common/ui/button';
import { Modal } from '../../../common/ui/modal';
import { FastLaunchLayout } from '../../../layouts/fastLaunch';
import '../common.scss';

export const FastLaunchPage3 = () => {
  const screenWidth = window.screen.width;

  const { referral } = useUrl();
  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const { response: curatorData } = useFetch({
    url: `/auth/curator?ref=${referral}`,
  });

  const modalRef = useRef(null);

  const { windowWidth } = useWindowSize();

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.height = `${
        modalRef.current.clientWidth * (9 / 16)
      }px`;
    }
  }, [modalRef, windowWidth]);

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  return (
    <FastLaunchLayout>
      <Col xs={12}>
        <Row className="justify-center">
          <Col lg={9} xl={7}>
            <button
              onClick={() =>
                openVideo(
                  'https://www.youtube.com/embed/nSO63mv9hJo?autoplay=1'
                )
              }
              className="mt-8 mb-3 relative"
            >
              <img alt="" src={videoPoster5} />

              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purpleDark w-24 h-16 md:w-40 md:h-24 rounded-xl flex items-center justify-center">
                <Icon name="playButton" size={40} color="#fff" />
              </span>
            </button>

            <p className="text-center">
              Все интересующие Вас вопросы вы всегда можете задать вашему
              куратору:
              <br />
              <span>
                {curatorData?.data?.last_name} {curatorData?.data?.first_name}{' '}
                {curatorData?.data?.patronymic}
              </span>
            </p>

            <div className="row -mx-2 justify-center mt-3 social-links">
              {curatorData?.data?.phone && (
                <div className="px-2 col-auto mb-3">
                  <a
                    className="link flex items-center no-underline"
                    href={`tel:${curatorData?.data?.phone}`}
                  >
                    <span className="mr-2 phone flex items-center justify-center icon">
                      <Icon color="#fff" size={16} name="phoneLogo" />
                    </span>

                    <span className="hidden lg:block text-text">
                      {new AsYouType().input(`+${curatorData?.data?.phone}`)}
                    </span>
                  </a>
                </div>
              )}

              {curatorData?.data?.tg && (
                <div className="px-2 col-auto mb-3">
                  <a
                    className="link tg flex items-center justify-center icon"
                    href={`https://telegram.me/${
                      curatorData?.data?.tg && (curatorData?.data?.tg).substr(1)
                    }`}
                  >
                    <Icon color="#fff" size={16} name="telegramFill" />
                  </a>
                </div>
              )}
              {curatorData?.data?.fb && (
                <div className="px-2 col-auto mb-3">
                  <a
                    className="link fb flex items-center justify-center icon"
                    href={`${curatorData?.data?.fb}`}
                  >
                    <Icon color="#fff" size={16} name="facebookLogo" />{' '}
                  </a>
                </div>
              )}
              {curatorData?.data?.sk && (
                <div className="px-2 col-auto mb-3">
                  <a
                    className="link sk flex items-center justify-center icon"
                    href={`Skype:${curatorData?.data?.sk}?add`}
                  >
                    <Icon color="#fff" size={16} name="skypeLogo" />{' '}
                  </a>
                </div>
              )}
              {curatorData?.data?.vk && (
                <div className="px-2 col-auto mb-3">
                  <a
                    className="link vk flex items-center justify-center icon"
                    href={`${curatorData?.data?.vk}`}
                  >
                    <Icon color="#fff" size={16} name="vkFill" />{' '}
                  </a>
                </div>
              )}
            </div>

            <Row className="justify-between mt-6 lg:mt-10 xl:mt-16">
              <Col xs={12} sm="auto" className="mb-3">
                <Link to={`/registration?referral=${referral ? referral : ''}`}>
                  <Button className="w-full" color="primary">
                    Зарегистрироваться
                  </Button>
                </Link>
              </Col>
              <Col xs={12} sm="auto" className="mb-3">
                <Link
                  to={`/fast-launch/step-4?referral=${
                    referral ? referral : ''
                  }`}
                >
                  <Button className="w-full" color="primary">
                    Подробнее о бизнесе с RSI
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Modal
        isOpen={isVideoVisible}
        modalStyles={{
          maxWidth: 1280,
          width: screenWidth < 600 ? '95%' : '60%',
          margin: 'auto',
        }}
        onClose={closeVideo}
        bodyStyles={{ padding: 6, height: '100%' }}
      >
        <div ref={modalRef}>
          {videoUrl && (
            <iframe
              className="modal-video"
              src={videoUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>
    </FastLaunchLayout>
  );
};
