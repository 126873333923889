import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { PageFooter } from '../../common/footer';
import { PageHeader } from '../../common/header';
import { MainMenu } from '../../common/mainMenu';
import styles from './index.module.scss';
import { Col } from 'reactstrap';
import { Modal } from '../../common/ui/modal';
import { Button } from '../../common/ui/button';
import offerta from '../../../assets/files/oferta.pdf';
import { useFetch } from '../../../hooks/useFetch';
import { apiPost } from '../../../api';
export const MainLayout = ({ children }) => {
  const ref = useRef();
  const backRef = useRef();
  const location = useLocation();
  const mainMenuToggle = () => {
    ref?.current?.classList.toggle('visible');
    backRef?.current?.classList.toggle('back-active');
  };

  const closeOutside = () => {
    ref?.current?.classList.remove('visible');
    backRef?.current?.classList.remove('back-active');
  };
  const {
    response: needShow,
    loading,
    fetchData: refrehShow,
  } = useFetch({ url: '/partner-offer/need-show' });

  useEffect(() => {
    ref?.current?.classList.remove('visible');
    backRef?.current?.classList.remove('back-active');
  }, [location]);

  const acceptShow = () => {
    apiPost({
      url: '/partner-offer/set-show',
    }).then(res => {
      if (res?.error) {
        alert(res?.data);
      }
      refrehShow();
    });
    
  };

  const acceptOferta = () => {
    apiPost({
      url: '/partner-offer/set-accept',
    }).then(res => {
      if (res?.error) {
        alert(res?.data);
      }
      acceptShow();
    });
  };
  return (
    <>
      <MainMenu backClose={() => closeOutside()} ref={{ ref, backRef }} />
      <div
        className={cx(
          'scroll-y min-h-full flex flex-col justify-between',
          styles.wrap
        )}
      >
        <PageHeader onPress={() => mainMenuToggle()} />

        <div className="flex-1 md:px-4">{children}</div>

        <PageFooter />
      </div>
      {!loading && (
        <Modal
          title={'Ознакомьтесь с условиями оферты и подтвердите согласие'}
          onClose={acceptShow}
          isOpen={needShow?.data}
          headerStyles={{ border: 'none' }}
          footerStyles={{ border: 'none', padding: '10px' }}
          settings="alert"
          bodyStyles={{ padding: '0px' }}
          modalStyles={{ margin: 'auto' }}
          footer={
            <div className="flex items-center justify-center">
              <Col xs="auto">
                <a className='btn btn-primary' href={offerta} target="_blank" rel="noreferrer">
                  Оферта
                </a>
              </Col>

              <Col xs={'auto'}>
                <Button onClick={acceptOferta} color="primary">
                  Принять
                </Button>
              </Col>
            </div>
          }
        />
      )}
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
};
