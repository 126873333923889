import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';

export const FastLaunchWelldone = () => {
  return (
    <Container fluid>
      <Card className="my-8 p-4">
        <Row className="items-center">
          <Col className="text-center">
            <div className="font-bold text-2xl"> Вы уже авторизованы</div>
            <Link to="/" className="mt-3 block text-main-color cursor-pointer">
              Вернуться на главную
            </Link>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};
