import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../hooks/useFetch';
import Spinner from '../../common/spinner';
import { Button } from '../../common/ui/button';
import { courseDesList } from '../../../global/course';
import { Modal } from '../../common/ui/modal';
import { Col } from 'reactstrap';
import {useDispatch} from 'react-redux';
import { apiGet, apiPost } from '../../../api';
import { updateWalletCount } from '../../../store/user/actions';
import qrCodeImg from '../../../assets/images/course2QrCode.jpeg';

export const CoursesLevel2In = () => {
  const { type } = useParams();

  const { response, loading } = useFetch({
    url: '/education/courses',
  });

  const data = response?.data?.filter(item => +item.type === +type)[0];

  const dispatch = useDispatch();
  // const wallet = useSelector(s => s.user.wallet);

  const [qrCodePayModalVisible, setQrCodePayModalVisible] = useState(false);

  const [isSuccessPaidAlertView, setIsSuccessPaidAlertView] = useState(false);
  const [successPaidText, setSuccessPaidText] = useState(false);

  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [productData, setProductData] = useState(null);

  const handleAlertOpen = text => {
    setAlertView(true);
    setAlertText(text);
  };
  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  const confirmPayment = async () => {
    const res = await apiPost({
      url: '/education/buy',
      postData: {
        type: productData.type,
      },
      headerList: {
        AppLoading: true,
      },
    });

    let text = 'Курс успешно куплен';

    if (res.error) {
      text = `Ошибка. ${res.data}`;
      console.error(res);
    } else {
      getBalanceWallet();
    }

    handleAlertClose();
    setSuccessPaidText(text);
    setIsSuccessPaidAlertView(true);
  };

  const onClick = () => {
    setProductData(data);
    handleAlertOpen(`Приобрести "${data.name}" за ${data.price} USDR?`);
  };

  const getBalanceWallet = () => {
    apiGet({
      url: '/wallet/usdr',
      // url: '/address',
    }).then(res => {
      dispatch(
        // updateWalletCount(+res.data.filter(i => i.name === 'USDR')[0]?.balance)
        updateWalletCount(+res.data.balance)
      );
    });
  };

  const payWithCard = () => {
    setQrCodePayModalVisible(true);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8">
            <div className="card p-4 mt-6">
              <p className="text-sm">{data.price} USDR</p>
              <p className="text-2xl font-medium">{data.name}</p>
              <p className="mt-1">Дата: с 10.01.2022</p>

              {/*<p className="mt-1 text-sm">{courseDesList[data.type]?.des}</p>*/}

              <div
                className="mt-8 text-sm"
                dangerouslySetInnerHTML={{
                  __html: courseDesList[data.type]?.fullDes,
                }}
              />

              <div className="row mt-10 items-center">
                <div className="col-auto text-lg mt-2">
                  Осталось мест {data.freePlaceCount} из {data.totalPlaceCount}
                </div>

                <div className="col-auto">
                  {data.isActive ? (
                    <a
                      href={data.inviteLink}
                      target="_blank"
                      className="btn btn-primary"
                      rel="noreferrer"
                    >
                      Начать обучение
                    </a>
                  ) : (
                    data.freePlaceCount > 0 && (
                      <div className="row">
                        {data.freePlaceCount > 0 && (
                          <>
                            <div className="col-auto mt-2">
                              <Button color="primary" onClick={onClick}>
                                Приобрести
                              </Button>
                            </div>

                            <div className="col-auto mt-2">
                              <Button color="primary" onClick={payWithCard}>
                                Оплатить картой или со счета
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="row justify-between p-3">
            <Col xs={'auto'}>
              <Button onClick={confirmPayment} color="primary">
                Да
              </Button>
            </Col>
          </div>
        }
      />

      <Modal
        title={successPaidText}
        onClose={() => setIsSuccessPaidAlertView(false)}
        isOpen={isSuccessPaidAlertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center p-3">
            <Button
              onClick={() => setIsSuccessPaidAlertView(false)}
              color="primary"
            >
              ОК
            </Button>
          </div>
        }
      />

      <Modal
        isOpen={qrCodePayModalVisible}
        title="Оплатить картой или со счета"
        onClose={() => setQrCodePayModalVisible(false)}
      >
        <img
          src={qrCodeImg}
          className="mb-6"
          style={{
            width: 280,
            height: 280,
          }}
        />
        <p>
          Стоимость 148,000 рублей
          <br />
          <br />
          Для оплаты курса с помощью карты или со счета ООО/ИП
          <br />
          <br />
          Вам необходимо навести камеру телефона на Qr-код, появится всплывающее
          окно со ссылкой, перейдите по ссылке и выберете удобный для Вас банк
          для оплаты, далее следуйте инструкциям Вашего банка. (в случае
          необходимости указания назначения платежа укажите: "Оплата
          образовательного мастер-класса RSI ФИО");
          <br />
          <br />
          Указать стоимость 148,000 рублей
          <br />
          <br />
          Также оплатить можно в мобильном приложении Вашего банка, выбрав
          раздел оплаты по Qr-коду. Чек по оплате необходимо отправить в
          telegram по номеру +79272447719. Комиссия за платеж не взимается.
          возможно оплатить с кредитной карты или карты рассрочки.
        </p>
      </Modal>
    </>
  );
};
