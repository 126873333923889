// /* eslint-disable */

import React, { useEffect, useState } from 'react';
import {Card, CardBody, Container, Table} from 'reactstrap';
import './index.scss';
import {apiGet} from '../../../../api';
import ReactPaginate from 'react-paginate';

export const SmartInStructure = () => {
  const [historyTable, setHistoryTable] = useState([]);
  const [tableLastPage, setTableLastPage] = useState(1);
  const [tablePage, setTablePage] = useState(1);

  useEffect(() => {
    apiGet({
      url: `/smart-index/structure?page=${tablePage}&limit=10`
    }).then(res => {
      if(res.error)
        return console.log(res);
      setHistoryTable(res?.data?.models);
      setTableLastPage(res.data.lastPage);
      console.log('res: ');
      console.log(res);

    }).catch(e => {
      console.log(e);
    });

  }, [tablePage]);

  const pageChanging = (data) => {
    setTablePage(data.selected + 1);
  };

  return (
    <Container fluid>
      <div className="md:flex justify-between">
        <div className="sm:text-2xl my-5 md:my-10 text-lg font-medium flex mx-auto">
                    Смарты в структуре
        </div>
      </div>

      <Card>
        <CardBody className="overflow-auto">
          <>
            <Table striped>
              <thead>
                <tr>
                  <th>Продажи / Покупки</th>
                  <th>Количество</th>
                  <th className={'text-center'}>Пользователь</th>
                  <th>Eдиницы</th>
                  <th>Общая стоимость</th>
                  <th>Дата покупки/продажи</th>
                </tr>
              </thead>
              <tbody>
                {historyTable.map(item =>
                  <tr key={item.id}>
                    <th scope='row' className={item.count < 0 ? 'text-red' : 'text-green'}>
                      {item.count < 0 ? <div className={'font-bold'}>Продано</div> :
                        <div className={'font-bold'}>Куплено</div>}
                    </th>
                    <td align='center'>
                      {item.count < 0 ? Math.abs(item.count) : item.count}
                    </td>
                    <td align='center'>
                      {item.user}
                    </td>
                    <td>
                      {item.rate.toFixed(2)}
                    </td>
                    <td>
                      {+(Math.abs(item.count * item.rate)).toFixed(2)}
                    </td>
                    <td>
                      {item.createdAt}
                    </td>
                  </tr>,
                )}
              </tbody>

            </Table>
            <div className='mx-auto flex justify-center mt-2'>
              <ReactPaginate
                pageCount={tableLastPage === 1 ? 0 : tableLastPage}
                pageRangeDisplayed={5}
                marginPagesDisplayed={3}
                onPageChange={pageChanging}
                activeClassName={'_active'}
                containerClassName={'cmp-pagination inline-flex'}
                pageLinkClassName={'flex items-center justify-center'}
                pageClassName={'flex items-center justify-center'}
                previousLabel={false}
                nextLabel={false}
                forcePage={tablePage ? +tablePage - 1 : 0}
              />
            </div>
          </>
                    
        </CardBody>
 
      </Card>
    </Container>
  );
};






































// import React, { useEffect, useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { useHistory } from 'react-router-dom';
// import { Card, CardBody, Container } from 'reactstrap';
// import updateUrl from '../../../../hooks/updateUrl';
// import { useFetch } from '../../../../hooks/useFetch';
// import useUrl from '../../../../hooks/useUrl';
// import { Pagination } from '../../../common/pagination';
// import Spinner from '../../../common/spinner';
// import { Select } from '../../../common/ui/select';
// import './index.scss';
//
// export const SmartInStructure = () => {
//   const { page } = useUrl();
//   const history = useHistory();
//
//   const { register, watch } = useForm();
//   const [filterStatusData, setFilterStatusData] = useState([
//     { label: 'Выбрать', value: '' },
//   ]);
//
//   const { response: smartInStructureData, loading: loadingSmartInStructure } =
//     useFetch({
//       url: `/smart/structure?page=${page || 1}&limit=10&status=${
//         watch('filterStatus') || ''
//       }`,
//     });
//
//   const { response: filterSmartStruture } = useFetch({
//     url: '/smart/get-search-attributes',
//   });
//
//   useEffect(() => {
//     if (filterSmartStruture) {
//       let arr = filterSmartStruture?.data?.status?.list.map((s, index) => {
//         return { label: s, value: index };
//       });
//
//       arr.unshift({ label: 'Выбрать', value: '' });
//
//       setFilterStatusData(arr);
//     }
//   }, [filterSmartStruture]);
//
//   useEffect(() => {
//     history.push(updateUrl({ key: 'page', value: '1' }));
//   }, [watch('filterStatus')]);
//
//   const renderTableRows = (data, index) => {
//     return (
//       <tr key={index}>
//         <td>{data?.id}</td>
//         <td>{data?.user}</td>
//         <td>{data?.amount}</td>
//         <td>{data?.createdAt}</td>
//         <td>{data?.statusText}</td>
//         <td>{data?.dividendsSum}</td>
//       </tr>
//     );
//   };
//
//   return (
//     <Container fluid>
//       <div className="md:flex justify-between">
//         <div className="sm:text-2xl my-5 md:my-10 text-lg font-medium flex ">
//           Смарты в структуре
//         </div>
//         <div className="flex items-center mb-2 md:mb-0">
//           <Select
//             fieldClass="m-0"
//             label="Статус"
//             list={filterStatusData}
//             name="filterStatus"
//             register={register}
//           />
//         </div>
//       </div>
//
//       <Card>
//         <CardBody className="overflow-auto">
//           {loadingSmartInStructure ? (
//             <Spinner styles={{ margin: 'auto' }} />
//           ) : (
//             <table className="table">
//               <thead>
//                 <tr>
//                   <th>№</th>
//                   <th>ПОЛЬЗОВАТЕЛЬ</th>
//                   <th>СУММА</th>
//                   <th>ДАТА ИНВЕСТИРОВАНИЯ</th>
//                   <th>СТАТУС</th>
//                   <th>НАЧИСЛЕНО</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {smartInStructureData?.data?.models.map(renderTableRows)}
//               </tbody>
//             </table>
//           )}
//         </CardBody>
//         <div className="text-center">
//           <Pagination
//             lastPage={smartInStructureData?.data.lastPage}
//             page={page}
//           />
//         </div>
//       </Card>
//     </Container>
//   );
// };
